import { useState } from "react";

import { ParentSize } from "@visx/responsive";
import { Dialog, } from "@material-ui/core";

import { SummationOfDoubleDeepObject,  convertEmptyKeyToFiller, makeDenseDoubleDeepObject } from "../Util/Functions";
import './LinkChart.scss';
import AreaChart from "./AreaChart";
import { MarketingLinkType, ChartDataType } from "../../../../Data/Shortner";

const resultLabel = {
    "referrer": '게시 위치별',
    "os": "기기별",
    "total": "링크별",
}

const LinkChart = ({ 
    data, date
}:{
    data: MarketingLinkType,
    date: string[]
}) => {

    const [ chartKey, setChartKey ] = useState<keyof typeof resultLabel | ''>('');

    if (!data) {
        return <></>;
    }

    let range = [];
    if (date.length === 2){
        for (let dt = new Date(date[0]); dt < new Date(date[1]); dt = new Date(dt.getTime() + 24 * 60 * 60 * 1000)) {
            range.push( dt.toISOString().substring(0, 10) );
        }
    }

    const unknownRangeDummy = [{'': Object.fromEntries(range.map(dt => [dt, 0]))}];

    let renderable = {
        os:       convertEmptyKeyToFiller(makeDenseDoubleDeepObject(
            Object.entries(data).map(([, obj]) => obj.os).concat(unknownRangeDummy).reduce(SummationOfDoubleDeepObject, {})
        )),
        referrer: convertEmptyKeyToFiller(makeDenseDoubleDeepObject(
            Object.entries(data).map(([, obj]) => obj.referrer).concat(unknownRangeDummy).reduce(SummationOfDoubleDeepObject, {})
        )),
        total:    convertEmptyKeyToFiller(makeDenseDoubleDeepObject(
            Object.fromEntries(Object.entries(data).map(([link, obj]) => [link, obj.total]).concat(Object.entries(unknownRangeDummy[0]) as [string, {[key: string]: number}][]))
        )),
    }
    
    return(
        <div className={'MarketingMetricChartsArea'}>
            {data && 
            <div className={'MarketingMetricChartsInner'} >
            
                <div className={'MarketingMetricRow'} key={"딥링크"} >
                    <div className={'MarketingPurpose'} >
                        <div className={'Title'}>
                            {"딥링크 현황 그래프"}
                        </div>
                    </div>
                    <div className={'MarketingChartContainer'}>

                        {(["referrer", "os", "total"] as const).map( type => {
                        
                            return [type, resultLabel[type], renderable[type]] as [keyof typeof resultLabel, string, {[key: string]: ChartDataType}];

                        }).map(([type, label, chartdata]) =>
                            ( <div className={'ChartOutlineArea'} key={type} >
                                <div className={'ChartPurpose'} >
                                    <div className={'TitleBox'} >
                                        <div className={'Title'} >{label}</div>
                                        <div className={'Detail'} onClick={() => setChartKey(`${type}`)} >{'자세히'}</div>
                                    </div>
                                </div>
                                <div className={'ChartSpace'}>
                                    <ParentSize>
                                        {({width, height}) => 
                                            <AreaChart width={width} height={height} data={chartdata} selectedSeries={new Set(Object.keys(chartdata))} />
                                        }
                                    </ParentSize>
                                </div>
                            </div>)
                        )}
                    </div>
                </div>
            </div>}
            <Dialog className={'ChartDetailDialog'} open={!!chartKey} onClose={() => setChartKey('')} PaperProps={{style:{maxWidth: 1000, margin: 0, width: 'calc(100% - 28px)'}}} >
                <div className={'ChartDetailDialogInner'} >
                    <div className={'ChartDetailTitleBox'}>
                        <div className={'Title'} >
                            {chartKey}
                        </div>
                        <div className={'SelectedPeriod'} >
                            {date.join(" - ")}
                        </div>
                    </div>
                    <div className={'ChartDetailChartBox'} >
                        <ParentSize>
                            {({width, height}) => {
                                if (chartKey === ''){
                                    return <></>;
                                }
                                let chartdata = renderable[chartKey];

                                return <AreaChart 
                                    width={width} height={height}
                                    data={chartdata} 
                                    showLegend={true} selectedSeries={undefined}
                                />
                            }}
                        </ParentSize>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default LinkChart;