import { OutlinedInputProps, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import WebtoonTodayNormalPopper from "./WebtoonTodayNormalPopper";

const LandingAutocomplete = ({ 
    options,
    value,
    defaultValue,
    onChange,
    onInputChange,
    placeholder,
    disabled,
    InputProps, inputProps
}:{ 
    options: string[]
    value?: string |undefined,
    defaultValue?: string |undefined,
    onChange?: (value: string) => void,
    onInputChange?: (value: string) => void 
    placeholder?: string |undefined,
    disabled?: boolean,
    InputProps?: Partial<OutlinedInputProps>,
    inputProps?: OutlinedInputProps['inputProps'],
}) => {
    return(<Autocomplete
        disabled={disabled}
        options={options}
        freeSolo={false}
        disableClearable={true}
        value={value}
        defaultValue={defaultValue}
        renderInput={(params) => <TextField
            {...params}
            label={""} variant={"outlined"}
            size={"small"} placeholder={placeholder}
            inputProps={{
                ...params.inputProps,
                ...inputProps,
                style: {
                    cursor: 'pointer',
                    caretColor: 'transparent',
                },
            }}
            InputProps={{
                ...params.InputProps,
                ...InputProps
            }}
        />}
        onChange={(event, value) => {
            if ( onChange ) { 
                onChange(value);
            }
        }}
        onInputChange={(event, value) => {
            if ( onInputChange ) {
                onInputChange(value);
            }
        }}
        onKeyDown={(e) => e.preventDefault()}
        PopperComponent={(props) => (
            <WebtoonTodayNormalPopper {...props} />
        )}
        style={{
            background: `${disabled?"linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #FFF":"#FFF"}`,
        }}
        ListboxProps={{
            style: {
                maxHeight: 200
            }
        }}
    />)
}

export default LandingAutocomplete;