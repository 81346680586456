import { atom, atomFamily, useRecoilState } from 'recoil';
import { getTitles, } from '../../Data/Title';
import { useCallback, } from 'react';
import { deepCompareObjects, unique } from '../../Functions';

const TitleKeyList = atom<string[]>({
    key: 'titleKeyList',
    default: [],
    dangerouslyAllowMutability: true
});
const TitleObject = atom<{[serviceIdAndtitleId: string]: TitleType}>({
    key: "titleObject",
    default: {},
    dangerouslyAllowMutability: true
});

export const keyListFamily = atomFamily<TitleKeyType[] | null, string>({
    key: 'keyListFamily',
    default: null
})

const clickLogsAtom = atom<InteractionLogType[]>({
    key: 'clickLogs',
    default: []
})

export const useClickLogs = (): {
    handleClickLogs: (clickInteractionLogs: InteractionLogType[]) => void
} => {
    const [ ,setClickLogs ] = useRecoilState<InteractionLogType[]>(clickLogsAtom);

    const handleClickLogs = useCallback((clickInteractionLogs: InteractionLogType[]) => {
        setClickLogs(clickLogs => [...clickLogs, ...clickInteractionLogs].filter(unique));
    },[setClickLogs]);

    return {handleClickLogs};
}

export const useTitles = () => {
    const [titleKeyList, setTitleKeyList] = useRecoilState(TitleKeyList);
    const [titleObject, setTitleObject] = useRecoilState(TitleObject);

    const fetch = useCallback(async (keys:{serviceId: string,titleId: string}[]) => {
        
        const newTitleList = await getTitles(keys.filter(key => key !== null));

        if (! newTitleList){
            return;
        }

        const newTitleObject = {} as {[key: string]: TitleType};

        for (let row of newTitleList){
            newTitleObject[`${row.serviceId}:${row.titleId}`] = row;
        }

        if (! deepCompareObjects(newTitleObject, titleObject)){
            setTitleObject(titleObject => ({...titleObject, ...newTitleObject}));
        }

    }, [setTitleObject, titleObject]);

    const refreshTitle = useCallback((keys:{serviceId: string,titleId: string}[]) => {
        setTitleKeyList(titleKeyList => [...titleKeyList, ...keys.map(({serviceId, titleId}) => `${serviceId}:${titleId}`)].filter(unique))

        fetch(keys)
    }, [fetch, setTitleKeyList]);

    const secureFetch = useCallback((keys:{serviceId: string,titleId: string}[])=>{
        let realNewList = keys.filter(row => !!row).filter(({serviceId, titleId}) => !titleKeyList.includes(`${serviceId}:${titleId}`));
        
        setTitleKeyList(titleKeyList => [...titleKeyList, ...realNewList.map(({serviceId, titleId}) => `${serviceId}:${titleId}`)].filter(unique))
        if (realNewList.length > 0){
            fetch(realNewList);
        }

    },[fetch, setTitleKeyList, titleKeyList])

    const isNotExist = useCallback((serviceId: string, titleId: string)=> {
        return !(`${serviceId}:${titleId}` in titleObject) && titleKeyList.includes(`${serviceId}:${titleId}`)
    },[titleKeyList, titleObject])

    return {fetch: secureFetch, refreshTitle, isNotExist, titleObject};
}

export const lastSeenTitleKey = atom<string>({
    key: "lastSeenTitleKey",
    default: "",
});
