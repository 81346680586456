import { useCallback, useRef } from 'react';
import {atom, useRecoilState} from 'recoil';
import { ShortLinkType, getShortLinkList } from '../../Data/Shortner';

const ShortnerList = atom<ShortLinkType[]>({
    key: "ShortnerObject",
    default: [],
    dangerouslyAllowMutability: true,
});

const DidShortnerFetch = atom<boolean>({
    key: "DidShortnerFetch",
    default: false,
    dangerouslyAllowMutability: true
})

export const useShortners = () => {
    const [didFetch, setDidFetch] = useRecoilState(DidShortnerFetch);
    const [shortnerList, setShortnerList] = useRecoilState(ShortnerList);

    const isRefreshing = useRef(false);

    const fetch = useCallback(async () => {
        const shortlinks: ShortLinkType[]|null = await getShortLinkList();

        if (!shortlinks) {
            return;
        }

        setShortnerList(shortlinks);
        isRefreshing.current = false;
    }, [setShortnerList]);

    const secureFetch = useCallback(()=>{
        if (didFetch){
            return;
        }
        setDidFetch(true);
        fetch();
    }, [didFetch, fetch, setDidFetch]);

    const refresh = useCallback(async () => {
        if (isRefreshing.current){
            return;
        }
        isRefreshing.current = true;
        fetch();
    },[fetch]);

    const isNotExist = useCallback(({serviceId, titleId}:{serviceId: string, titleId: string}) => {
        return !(shortnerList.find(row => row.serviceId === serviceId && row.titleId === titleId)) && didFetch
    },[didFetch, shortnerList]);

    return {fetch: secureFetch, refresh, isNotExist, shortnerList};
}