import { Button } from '@material-ui/core';
import React from 'react';
import { logBehavior } from '../../../../../Data/Behavior';

let naverLogin:any = null;
const Naver = 'https://static.webtoon.today/challenge/NaverIcon.png';
const NAVER_CALLBACK_URL = window.location.protocol+"//"+window.location.host+'/home/signin/navercallback';
const NAVER_CLIENT_ID = "J4koZJjh97_S8k6Oyv99";

const NaverLogin = ({ onSuccess }:{onSuccess: (e:any)=>void}) => {
    
    const naverRef = React.useRef<HTMLDivElement>(null);
    

    React.useEffect(() => {
        const { naver } = (window as any);
        
	    const initializeNaverLogin = () => {
            naverLogin = naverLogin || new naver.LoginWithNaverId({
                clientId: NAVER_CLIENT_ID,
                callbackUrl: NAVER_CALLBACK_URL,
                isPopup: true,
                loginButton: { color: 'green', type: 3, height: 58 },
                callbackHandle: false,
            })
            naverLogin.init()};

        initializeNaverLogin();

        (window as any).updateNaverSession = async (sessionData: any) => {

            let data = Object.fromEntries(sessionData.url.hash.replace('#','').split("&").map((pair: string) => pair.split("=")))
            if(new URLSearchParams(sessionData.url.href).get('error')){
                logBehavior('login-complete-snsLogin(fail)', {});
            } else{
                logBehavior('login-complete-snsLogin(success)', {});
            }
            onSuccess(data);
        }

    }, [onSuccess]);

    const handleNaverLogin = () => {
        (naverRef?.current?.children[0] as HTMLElement).click();
    };

	return (
		<>
			<div id={"naverIdLogin"} ref={naverRef} hidden />
            <Button
                onClick={() => {
                    logBehavior('login-start-snsLogin', {});
                    handleNaverLogin();
                }}
                className={'Button NaverLoginButton'}
                style={{
                    backgroundColor: 'rgb(3,199,90)',
                    color:'white',
                    height: '45px',
                    fontFamily: 'inherit',
                    textTransform: 'none'
                }}
            >
                <img src={Naver} alt={'naverLogo'} style={{maxHeight: '40px', position: 'absolute', left: '5px'}} />
                <span>{'Naver 로그인'}</span>
            </Button> 
		</>
	)
}

export default NaverLogin;