import {
    AnimatedAxis,
    AnimatedLineSeries,
    AreaStack,
    Grid,
    XYChart,
    Tooltip
} from "@visx/xychart";
import moment from 'moment-timezone';
import { curveLinear } from '@visx/curve';
import { LegendItem, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import './MarketingResultChartArea.scss';
import { LinearGradient } from "@visx/gradient";
import { memo, useState } from "react";
import { Tooltip as MaterialTooltip } from "@material-ui/core";
import { numericUnitConverter } from "../Util/Functions";

const colorScale: string[] = [
    'rgb(255, 187, 203)',
    'rgb(255, 226, 170)',
    'rgb(191, 239, 168)',
    'rgb(182, 205, 255)',
    'rgb(220, 186, 255)',
    'rgb(253, 148, 148)',
    'rgb(255, 242, 124)',
    'rgb(148, 214, 117)',
    'rgb(134, 165, 236)',
    'rgb(185, 136, 208)',

    'rgb(245, 177, 193)',
    'rgb(245, 216, 160)',
    'rgb(181, 229, 158)',
    'rgb(172, 195, 245)',
    'rgb(210, 176, 245)',
    'rgb(233, 138, 138)',
    'rgb(215, 232, 94)',
    'rgb(118, 174, 77)',
    'rgb(104, 145, 206)',
    'rgb(145, 106, 178)',
]


const AreaChart = ({
        width: givenWidth, height: givenHeight,
        data,
        showLegend = false, selectedSeries
    }:{
        width: number, height: number,
        data: {[series: string]: {[date:string]: number | string}},
        showLegend? : boolean, selectedSeries?: Set<string>
    }) => {

        const chartMargin = showLegend
                            ?{ left: 60, top: 40, bottom: 30, right: 10 }
                            :{ left: 40, top: 20, bottom: 20, right: 15 }
        
        const yScale: any = {
            type: 'linear',
            zero: false,
            nice: true
        };

        const [ open, setOpen ] = useState<string>('');

        const lastDay = Object.keys(Object.values(data)[0] || {}).sort().reverse()[0];
        const xLabelArray = Object.keys(data);
        const width = showLegend? givenWidth * 0.8 : givenWidth;
        const legendBoxWidth = showLegend ? givenWidth * 0.2 : 0;
        const height = givenHeight;
        const legendScale = {
            domain: Object.keys(data).filter( series => selectedSeries?selectedSeries.has(series):true ),
            range: colorScale
        }

        return(
            <div className={'MarketingResultChartArea'} >
                <XYChart
                    height={height}
                    width={width}
                    xScale={{ type: 'point', padding: 0.2 }}
                    yScale={yScale}
                    margin={chartMargin}
                >
                    {showLegend?<><Grid 
                        columns={false}
                        numTicks={4}
                        strokeWidth={0.5}
                        stroke={"rgba(223, 223, 223, 0.7)"}
                        lineStyle={{}}
                    />
                    <Grid
                        rows={false}
                        numTicks={5}
                        strokeWidth={0.5}
                        stroke={"rgba(223,223,223,0.7)"}
                        lineStyle={{}}
                    /></>:<></>}
                    <AnimatedAxis
                        orientation="bottom"
                        numTicks={5}
                        strokeWidth={1}
                        tickFormat={(d: string) => moment(d).format('DD')}
                        hideZero={false}
                        hideTicks={true}
                        hideAxisLine={true}
                        tickLabelProps={()=>({
                            style:{
                                fontSize: '0.625rem',
                                fontFamily: 'inherit'
                            }
                        })}
                    />
                    
                    <AnimatedAxis
                        orientation="left"
                        numTicks={4}
                        strokeWidth={1}
                        labelClassName={'YLabel'}
                        hideZero={false}
                        hideTicks={true}
                        tickFormat={ (d: string) => numericUnitConverter(d, 0) }
                        stroke={"black"}
                        hideAxisLine={true}
                        tickLabelProps={()=>({
                            style: {
                                wordBreak: 'keep-all',
                                fontFamily: 'inherit',
                                fontSize: '0.625rem',
                            }
                        })}
                    />
                    <LinearGradient
                      id="area-chart-gradient"
                      from={"#519EE9"}
                      to={"#519EE9"}
                      toOpacity={0.1}
                    />
                    <AreaStack curve={curveLinear} >
                        {Object.entries(data)
                            .filter( ([series]) => selectedSeries?selectedSeries.has(series):true )
                            .map( ([series, chartData], idx) =>
                            <AnimatedLineSeries
                                key={`${series}`}
                                dataKey={series}
                                data={Object.entries(chartData).sort( ([key1,],[key2,]) => {
                                    return new Date(key1).getTime() - new Date(key2).getTime();
                                }).map(([date, value]) => {
                                    if(date === lastDay && value === 0){
                                        return ([date, undefined]);
                                    }else{
                                        return ([date, value]);
                                    }
                                })}
                                xAccessor={(d) => new Date(`${d[0]}Z00:00:00`)}
                                yAccessor={(d) => (d[1]===undefined)?d[1]:Number(d[1]) > 0?d[1]:0}
                                fillOpacity={0.4}
                                fill={colorScale[xLabelArray.indexOf(series)]}
                            />
                        )}
                    </AreaStack>
                    <Tooltip
                        showVerticalCrosshair
                        renderTooltip={({ tooltipData }) => (
                            <div key={`${showLegend}:`} >
                                {(tooltipData?.nearestDatum?.datum as [string, number])[0]}
                                <br />
                                <div key={tooltipData?.nearestDatum?.key} >
                                    <span
                                        style={{
                                            color: colorScale[xLabelArray.indexOf(tooltipData?.nearestDatum?.key as string)],
                                            fontFamily: 'inherit'
                                        }}
                                    >
                                    {tooltipData?.nearestDatum?.key}
                                    </span>
                                    {" : " + (tooltipData?.nearestDatum?.datum as [string, number])[1]?.toLocaleString()}
                                </div>
                            </div>
                        )}
                    />
                </XYChart>
                {showLegend?
                <div className={'LegendBox'} style={{width: legendBoxWidth}} >
                    <LegendOrdinal 
                        scale={scaleOrdinal(legendScale)} 
                        direction={'column'}
                        legendLabelProps={{
                            style:{
                                margin: 0,
                            }
                        }}
                        shapeMargin={'1px 2px 0 1px'}
                    >
                        {(labels) => (
                        <div className={'LegendOutline'} >
                            {labels.reverse().map((label, i) => (
                                <LegendItem
                                    key={`dialog-legend-quantile-${i}`}
                                    onClick={(events: React.MouseEvent<HTMLElement>) => {
                                        if (events) {
                                            setOpen(`${label.text}`);
                                        }
                                    }}
                                    style={{cursor: 'pointer'}}
                                >
                                    <svg className={'visx-legend-shape'} >
                                    <rect className={'visx-legend-shape-square'} fill={label.value} />
                                    </svg>
                                        <MaterialTooltip
                                            title={label.text} open={open === label.text?true:false} onClose={ () => setOpen('')}
                                            enterDelay={500} leaveDelay={300} arrow={true} placement={'left'}
                                        >
                                            <div className={'visx-legend-label'} >{label.text}</div>
                                        </MaterialTooltip>
                                </LegendItem>
                            ))}
                        </div>
                        )}
                    </LegendOrdinal>
                </div>:<></>}
            </div>
        )
    }

export default memo(AreaChart);
