import { Button, Drawer, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { BranchFunction, fn } from "../../Functions";

import './index.scss';
import { pageHeaderOptions } from "../../Recoil/PageHeader/PageHeader";
import { useSetRecoilState } from "recoil";
import OneColumnFullpageDialog from "../../Component/OneColumnFullpageDialog";

import { useSession, useSignIn } from "../../Recoil/Session/Session";
import { Close, NotInterested, Menu } from "@material-ui/icons";
import Link from "./Link/Link";
import Dashboard from "./Dashboard";
import BorderLine from "../../Component/BorderLine";
import SVGComponent from "../../Component/SVGComponent";
import SignIn from "./SignIn";
import UseAnimation from "../../Hook/UseAnimation";

type pages = (
    'home'|
    'dashboard'|
    'signin'|
    'somethingwrong'
);

const OCFD = ({children}:{children:ReactNode}) => {
    return <OneColumnFullpageDialog style={{position: "relative", width: '100%', height:'inherit', minHeight: 'calc(100vh - 80px)'}}>
        {children}
    </OneColumnFullpageDialog>
}

const Home = ({path, query, hash}:{path: string, query: string, hash?: string}) => {
    const {session} = useSession();
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    
    const setPageHeaderOption = useSetRecoilState(pageHeaderOptions);

    useEffect(()=>{
        setPageHeaderOption({
            isHiddenForMobile: true,
            isHiddenForPC: true,
            isAppbarHidden: true,
        })
    },[setPageHeaderOption])
    
    const currentBranch = useMemo(() => BranchFunction<pages, string>(
        path,[
            {
                validator: (path) => /^\/home\/signin/i.test(path) || session.memberState !== 'regular',
                defaultValue: 'signin',
            },
            {
                validator: (path) => /^\/home\/dashboard/i.test(path),
                defaultValue: 'dashboard',
            },
            {
                validator: (path) => /^\/home\/link/i.test(path) || /^\/home/i.test(path),
                defaultValue: 'home',
            },
        ],
        'somethingwrong'
    ), [path, session.memberState]);
    
    const container = useMemo(()=>{
        switch (currentBranch) {
            case "home":
                return <Link />;
            case "dashboard":
                return <Dashboard hash={hash}/>;
            case "somethingwrong":
            default:
                return <OCFD>{"뭔가 문제가 있습니다!"}</OCFD>;
        }
    }, [currentBranch, hash])

    const [isDrawerOpen ,setIsDrawerOpen] =useState(window.innerWidth > 700);

    if(currentBranch === 'signin'){
        return <SignIn path={path.replace(/^\/home/i,'')} query={query} />;
    }

    return (<div className={'HomeSweetHome'}>
        <Drawer
            variant="permanent" className={`Sidebar ${isDrawerOpen?'Opened':'Closed'}`} anchor={"left"}
            open={isDrawerOpen} onClose={()=>setIsDrawerOpen(false)}
        >
            <div className={`HomeDrawerHeader`}>
                <a className={'LogoImage'} href={"/home"} onClick={fn.gotoByAnchor} >
                    <img className={'Long'} src={'https://static.webtoon.today/ddah/icon/logo_webtly.svg'} alt={'WebtlyLogo'} onClick={() => fn.goto("/home")} />
                    <img className={'Short'} src={'https://static.webtoon.today/ddah/icon/logo_w.svg'} alt={'WebtlyLogo'} onClick={() => fn.goto("/home")} />
                </a>
                <HomeDrawerMenu />
            </div>
            <BorderLine style={{marginBottom: 12}} />
            <div className={`HomeDrawerBody`}> 
            {([
                {Icon: <SVGComponent className={'SVGIconLink'} src={'https://static.webtoon.today/ddah/icon/link_with_arrow.svg'} />, label: "링크", link: '/home', pages: ['home','link']},
                {Icon: <SVGComponent className={'SVGIconDashboard'} src={'https://static.webtoon.today/ddah/icon/bar_chart_square.svg'} />, label: "대시보드", link: '/home/dashboard', pages: ['dashboard']},
                // TODO logout 추가 되면 다시 코드를 활성화
                // {Icon: <NotInterested/>, label: "로그아웃", link: '/logout', pages: []}
            ]).map(({Icon, label, link, pages}:{Icon:ReactNode, label: ReactNode, link: string, pages: string[]}) => (
            <Button fullWidth key={link} className={`Row${pages.includes(currentBranch)?" Selected":""}`} disabled={!link} onClick={()=>fn.goto(link)}  >
                <ListItemIcon>{Icon}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
            </Button>) )}
            
            <Button fullWidth className={'Row SideFooter'} onClick={()=>setIsDrawerOpen(!isDrawerOpen)}>
                {isDrawerOpen
                ?<ListItemIcon><img src={'https://static.webtoon.today/ddah/marketinglanding/icon-collapse.svg'} alt={'fold'}/></ListItemIcon>
                :<ListItemIcon><img src={'https://static.webtoon.today/ddah/marketinglanding/icon-expand.svg'} alt={'open'}/></ListItemIcon>}
            </Button>
            </div>
        </Drawer>
        <div className={"CustomsSpace"}>
            <Menu className={'MobileMenuIcon'} onClick={ () => setIsOpen(true) } />
            <PageMovingMenu {...{isOpen, setIsOpen, currentBranch}} />

            <a className={'MobileLogoBox'} href={"/home"} onClick={fn.gotoByAnchor} >
                <img className={'MobileLogo'} src={'https://static.webtoon.today/ddah/icon/logo_w.svg'} alt={'WebtlyMobileLogo'} onClick={() => fn.goto("/home")} />
            </a>
            <a className={'MobileUserImageBox'} href={"/home"} onClick={fn.gotoByAnchor} >
                <img className={'MobileUserImage'} src={session.image || "https://static.webtoon.today/noprofile.jpg"} alt={'profileImage'} onClick={() => fn.goto("/home")} />
            </a>
        </div>
        <div className={`Container  ${isDrawerOpen?'Opened':'Closed'}`}>
            {container}
        </div>
    </div>);
}

const PageMovingMenu = ({isOpen, setIsOpen}:{isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const { logout } = useSignIn();
    if (!isOpen) return <></>;

    return(<div className={'PageMovingMenuArea'} onClick={() => setIsOpen(false)} >
        <div className={'PageMovingMenuCloseButton'} >
            <Close />
        </div>
        <div className={'PageMovingMenuContainer'} >
            {([
                [<><SVGComponent className={'SVGIconLink'} src={'https://static.webtoon.today/ddah/icon/link_with_arrow.svg'} />{"링크"}</>, () => {fn.goto(`/home`)} ],
                [<><SVGComponent className={'SVGIconDashboard'} src={'https://static.webtoon.today/ddah/icon/bar_chart_square.svg'} />{"대시보드"}</>, () => {fn.goto(`/home/dashboard`)} ],
                [<><NotInterested/>{"로그아웃"}</>, () => {logout(); fn.goto('/home');} ],
            ] as const).map(([label, action]) => (
            <div
                className={`ButtonBox`} key={`MarketLanding:${label.props.children[1]}`}
                onClick={() => {
                    action();
                }}
            >
                {label}
            </div>))}
        </div>
    </div>)
}

const HomeDrawerMenu = () => {
    const { session } = useSession();
    const {logout} = useSignIn();

    const myMenu = useRef<HTMLDivElement |null>(null);
    const [ isMyMenuOpen ,setIsMyMenuOpen ] =useState<boolean>(false);
    const { isRender, onTransitionEnd, isAnimating } = UseAnimation(isMyMenuOpen);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const menuButton = document.getElementById("MyMenuButton");
            if( myMenu.current && menuButton && !myMenu.current.contains(event.target) && !menuButton.contains(event.target) ) {
                setIsMyMenuOpen(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    },[])

    return(<div className={'MyMenu'} id={"MyMenuButton"} >
        <Button fullWidth className="ProfileButton" onClick={()=>setIsMyMenuOpen(prev => !prev)} >
            <div className={'ProfileInfo'}>
                <img className={'UserImageBox'} src={session.image || "https://static.webtoon.today/noprofile.jpg"} alt={'img'} />
                <Tooltip title={session.name || ""} ><span className={'UserName'}>{session.name}</span></Tooltip>
            </div>
            <img src={"https://static.webtoon.today/ddah/icon/triangle_down.svg"} alt={"triangle"} className={`MyMenuButton ${isMyMenuOpen && "Open"}`}/>
        </Button>
        {isRender && <div className={`MenuDropDown ${isAnimating?"MenuOpen":"MenuClose"}`} onTransitionEnd={() => onTransitionEnd()} ref={myMenu} >
            {[{label: '로그아웃', onClick: () => {logout(); fn.goto('/home');}}].map( ({label, onClick}:{label: string, onClick: Function}) => (
                <Button className={`MenuDropDownItem`} onClick={() => onClick()} key={label} >
                    {label}
                </Button>
            ))}
        </div>}
    </div>)
}

export default Home;