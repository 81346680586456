import React, { useEffect } from 'react';
import VirtualRouter from './VirtualRouter';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { MuiThemeProvider, createTheme, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { fn } from './Functions';
import { RecoilRoot } from 'recoil';
import Header from './Header';
import AppBar from './Component/AppBar';
import {Toast} from '@webtoontoday/toast';


const App = (props: {isClient: boolean, injectedLocation?: string}) => {
    /** @type {[sessionType, (newSession:sessionType)=>void]} */
    const [alertText, setAlertText]= React.useState<string | undefined>(undefined);
    const alert = React.useMemo(()=>(message: string)=>setAlertText(message),[setAlertText]);
    
    useEffect(()=>{
        fn.alert = alert;
    },[alert])
    
    useEffect(()=>{
        let firstTag: ChildNode | null = document.getElementsByTagName('body')[0].firstChild;
        if (firstTag && !firstTag.hasChildNodes()){
            document.getElementsByTagName('body')[0].removeChild(firstTag);
        }
    },[])


    /** @type {globalDataType} */
    const routingProps = {
        isClient: props.isClient, injectedLocation: props.injectedLocation
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(0,176,240)',
            }
        },
        overrides: {
            MuiButton: {
                containedPrimary: {
                    color: 'white',
                },
            },
        }
    })

    return (
        <RecoilRoot>
            <MuiThemeProvider theme={theme}>
                <DndProvider backend={HTML5Backend}>
                    <div className="App">
                        <Header isClient={props.isClient} />
                        <VirtualRouter {...routingProps} />
                        <AppBar />
                        <Toast />
                    </div>
                </DndProvider>
            </MuiThemeProvider>
            <Dialog open={!!alertText}>
                <DialogContent>{alertText}</DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={()=>setAlertText(undefined)}>{"확인"}</Button>
                </DialogActions>
            </Dialog>
        </RecoilRoot>
    );
}

export default App;
