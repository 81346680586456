import { CSSProperties, useEffect, useRef, useState } from "react";
import WithLabel from "../../../../Component/WithLabel";
import LandingAutocomplete from "../../../Landing/Components/LandingAutocomplete";
import { searchByTitle, searchResultType } from "../../../../Data/Search";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { computeFuzzyDistance } from "../../../../Functions";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { useEpisodes } from "../../../../Recoil/Title/Episodes";
import WebtoonTodayNormalPopper from "../../../Landing/Components/WebtoonTodayNormalPopper";

import './ShortLinkGenerator.scss';
import { useTitles } from "../../../../Recoil/Title/Titles";
// 이 페이지 input 요소의 높이

const PlatformInfo = ({
    serviceId, titleId, episodeId,
    disabled = false,
    onSelectTitleId,
    onSelectEpisodeId,
    contentStyles,
    InputHeight = 50,
}:{
    serviceId?: "kakao" | "naver" | "other" |undefined,  
    titleId?: string |undefined, 
    episodeId?: string |undefined,
    disabled?: boolean,
    onSelectTitleId: (searchResult: string |undefined) => void,
    onSelectEpisodeId: (episode: string | undefined) => void,
    contentStyles?: CSSProperties
    InputHeight?: number,
}) => {
    const { titleObject } = useTitles();
    const { fetch: fetchEpisodes, episodeObject } = useEpisodes();
    
    const [ titleList, setTitleList ] = useState<searchResultType[]>([]);
    const [ episodeList, setEpisodeList ] = useState<EpisodeType[]>([]);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    
    const titles = titleList.map( ({title}:{title:string}) => title );
    const episodes = ["작품홈"].concat(episodeList.map( ({episodeName}:{episodeName: string}) => episodeName)).filter((episode)=>episode);

    const title = titleObject[`${serviceId}:${titleId}`];
    const episode = episodeObject[`${serviceId}:${titleId}`]?.find( episodeObj => episodeObj.episodeId === episodeId);
    
    const onWebtoonTitleInputChange = async (title: string ) => {
        setIsLoading(true);
        
        const restitleList = await searchByTitle(title);

        if (!restitleList){
            setTitleList([]);
        } else {
            setTitleList(restitleList.filter((title)=>title.serviceId === serviceId));
        }
        
        setIsLoading(false);
    }

    useEffect(() => {
        setEpisodeList(episodeObject[`${serviceId}:${titleId}`] || []);
    },[episodeObject, serviceId, titleId])

    useEffect(() => {
        (async () => { 
            if (serviceId && titleId) {
                fetchEpisodes({serviceId, titleId})
            }
        })()
    },[fetchEpisodes, serviceId, titleId])
    
    const readTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [ open, setOpen ] = useState<boolean>(Boolean(title?.title));
    const [ text, setText ] = useState<string |undefined>(title?.title);
    
    const filteredTitleOptions = open ? titles : [];
    return(<>
    <WithLabel label={'작품 제목'} contentStyles={contentStyles} >
        <Autocomplete
            autoHighlight
            value={text}
            disabled={!serviceId || disabled}
            forcePopupIcon={false}
            disableClearable={true}
            disabledItemsFocusable
            loading={isLoading}
            loadingText={"검색 결과를 불러오고 있습니다."}
            freeSolo={(!open && !isLoading)}
            noOptionsText={"검색 결과가 없습니다."}
            clearOnBlur={false}
            options={filteredTitleOptions}
            getOptionLabel={(option) => option}
            onChange={ (event, newValue) => {
                setText(newValue);
                ( async () => {

                    const title = titleList.find( title => title.title === newValue ) 
                    if ( !title ) {
                        return;
                    }
                    const { serviceId, titleId } = title;
                    onSelectTitleId(titleId);

                    await fetchEpisodes({serviceId, titleId});
                    
                })()
            }}
            onInputChange={(event, value) => {
                if (readTimer.current){
                    clearTimeout(readTimer.current);
                }

                readTimer.current = setTimeout( async () => {
                    onWebtoonTitleInputChange(value);
                }, 300);
                if (value.length > 0 && !open) {
                    setOpen(true);
                }else if (value.length === 0){
                    setText(undefined);
                    setOpen(false);
                }
            }}
            filterOptions={(options, state) => {
                const whiteSpaceReg = /(^\s+)|(\s+(?=\s))/gu;

                const filteredOption = options
                    .map((option) => [option, computeFuzzyDistance(state.inputValue.replace(whiteSpaceReg, ''), option)] as [string, number])
                    .filter(([, distance]) => distance >= 0)
                    .sort(([, distanceA], [, distanceB]) => distanceA - distanceB)
                    .map(([option]) => option);
                return filteredOption;
            }}
            renderInput={(params) => <TextField
                placeholder={"작품 제목 검색"}
                {...params} label="" variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>
                        {open && isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                        <InputAdornment className={'EndAdornmentIcon'} position='end'>
                            {<img src={'https://static.webtoon.today/ddah/icon/search.svg'} alt={'SearchIcon'} style={{paddingRight: 6}} />}
                        </InputAdornment>
                    </>),
                    style: {
                        height: InputHeight
                    },
                }}
                size={'small'}
                disabled={!serviceId || disabled}
                style={(!serviceId || disabled)?{
                    background: "linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #FFF"
                }:{}}
            />}
            PopperComponent={(props) =>  (<>
                {titleList.length > 0 &&<WebtoonTodayNormalPopper {...props} />}
            </>)} 
            ListboxProps={{style:{
                maxHeight: 300
            }}}
        />
    </WithLabel>
    <WithLabel label={'도착 페이지'} contentStyles={contentStyles} >
        <LandingAutocomplete
            options={episodes}
            defaultValue={episode?.episodeName}
            onChange={ (episode) => {
                const episodeObj = episodeList.find( ({episodeName}) => `${episodeName}` === episode );
                if ( episodeObj ) {
                    onSelectEpisodeId(episodeObj.episodeId);
                }else {
                    onSelectEpisodeId(undefined);
                }
            }}
            placeholder={"선택해주세요."}
            disabled={!serviceId || !titleId || disabled}
            InputProps={{
                style: {
                    height: InputHeight
                }
            }}
            key={`${text}:${episodeId}`}
        />
    </WithLabel></>)
}

export default PlatformInfo;