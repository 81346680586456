import axios from 'axios';

const crypto = require('crypto');

const nullSession = {userid:null, addressid: null, token:null, email: null, jwt: '', memberState: null, image: null, gender: null, birthday: null, name: null};
const nullBucket = {};
const crnt = {
    session: global.localStorage?JSON.parse(localStorage.getItem('session') || '{}'):nullSession,
    bucket: global.localStorage?JSON.parse(localStorage.getItem('bucket') || '{}'):nullBucket,
};

export const hashForABTest = (userid:string, salt: string) => {

    let key = [];
    let hashInteger = 0;
    let hash = 0.0;
    try{
        key = crypto.pbkdf2Sync(userid, salt, 20, 64, 'sha512');
        for (var i = 0; i < key.length; i++) {
            hashInteger = (hashInteger << 8) | key[i];
        }
        hash = (Math.abs(hashInteger) % 100) / 100.0;
    }catch(err){
        console.log(err , key)
    }
    return hash;
}

export const bucketForABTest = (hash:number, numberOfBuckets:number) => {
    let buckets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return buckets[Math.floor(hash * numberOfBuckets)];
}

const sessionRefresh = async () => {
    const session = crnt.session;
    
    try{

        let res = await axios.post(
            'https://challenge-api.webtoon.today/session', 
            (session.userid && session.token)?{userid: session.userid, token: session.token}:{}
        );
        
        if (res.data.code === 200 && res.data.data && res.data.data.userid){
            if (!res.data.data.image){
                res.data.data.image = "https://static.webtoon.today/noimage.jpg";
            }
            crnt.session = res.data.data;
        } else {
            crnt.session = nullSession;
        }
    }catch(e){
        crnt.session = nullSession;
    } finally{
        return crnt.session;
    }
}       


const EmailLogin = async (email:string, password:string) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    }
    
    if (email) {
        try{
            let res = await axios.post(
                'https://challenge-api.webtoon.today/session',
                {email, password}
            );
            
            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
};

const EmailSignup = async ({email, otp, password, addressid, name, gender, birthday}: {email: string, otp: string, password: string, addressid: string, name: string, gender: string, birthday: string}) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    }
    
    if (email) {
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {email, otp, password, addressid, name, gender, birthday, image: "https://static.webtoon.today/noimage.jpg"}
            );
            
            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
};

const EmailTokenSignin = async (email: string, token: string) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    }
    if (email){
        try{
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {email, token}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.log(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}



export const AskResetPassword = async ({email}: {email: string}) => {
    try {
        
        let res = await axios.post('https://challenge-api.webtoon.today/user/password-reset', {email}, {
            headers: {
                Authorization: `Bearer ${crnt.session.jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}

const GoogleSignin = async (response: {code: string|number}, ) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    };

    if (response.code){
        try{
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {gsso: response}
            );
            
            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

const logout = async () => {
    crnt.session = nullSession;
    return crnt.session;
}

 const FacebookSignin = async (response: {accessToken: string}, ) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    }
    if (response.accessToken){
        try{
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {fsso: response}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

 const NaverSignin = async (response: {access_token: string}, ) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    }
    try{
        
        if (!response.access_token){
            throw new Error("no access token!");
        }

        let res = await axios.put(
            'https://challenge-api.webtoon.today/session',
            {nsso: response}
        );

        if (res.data.code === 200 && res.data.data && res.data.data.userid){
            if (!res.data.data.image){
                res.data.data.image = "https://static.webtoon.today/noimage.jpg";
            }
            crnt.session = res.data.data;
        }else{
            crnt.session = nullSession;
        }
    }catch(e){
        console.error(e);
    } finally{
        return crnt.session;
    }
}


 const KakaoSignin = async (response:{response: {access_token: string}}, ) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    }
    if (response.response.access_token){
        try{
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {ksso: response}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

const sessionFuncs = {
    GoogleSignin,
    FacebookSignin,
    NaverSignin,
    KakaoSignin,
    sessionRefresh,
    EmailLogin,
    EmailSignup,
    EmailTokenSignin,
    logout,
    getCurrentSession: () => {return crnt.session},
    getCurrentBucket: () => {return crnt.bucket},
};

export default sessionFuncs;
