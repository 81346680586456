import { CSSProperties } from "react";

const BorderLine = ({
    width = "100%", height = 2, fill = "none", stroke = "rgba(236, 237, 243, 1)", style
}:{
    width?: number |string, height?: number, fill?: string, stroke?: string, style?: CSSProperties
}) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 240 2" fill={fill} style={{...style}}>
            <path width={'100%'} d="M0 1H240" stroke={stroke}/>
        </svg>
    )
}

export default BorderLine;