import { useCallback, useRef } from 'react';
import {atom, useRecoilState} from 'recoil';
import { readEpisode } from '../../Data/Episode';

const EpisodeKeyList = atom<string[]>({
    key: "EpisodeKeyList",
    default: [],
    dangerouslyAllowMutability: true,
});

const EpisodeObject = atom<{[serviceIdAndTitleId: string]: EpisodeType[]}>({
    key: "EpisodeObject",
    default: {},
    dangerouslyAllowMutability: true,
});

export const useEpisodes = (): {
    fetch: ({serviceId, titleId}:{serviceId: string, titleId: string}) => void,
    isNotExist: ({serviceId, titleId}:{serviceId: string, titleId: string}) => void,
    refresh: ({serviceId, titleId}:{serviceId: string, titleId: string}) => Promise<void>,
    episodeObject: {[serviceIdAndTitleId: string]: EpisodeType[]},
} => {
    const [episodeKeyList, setEpisodeKeyList] = useRecoilState(EpisodeKeyList);
    const [episodeObject, setEpisodeObject] = useRecoilState(EpisodeObject);

    const isRefreshing = useRef(false);

    const fetch = useCallback(async ({serviceId, titleId}:{serviceId: string, titleId: string}) => {
        const episodes: EpisodeType[] = await readEpisode(serviceId, titleId);

        if (!episodes) {
            return;
        }

        setEpisodeObject(episodeObject=> ({
            ...episodeObject,
            [`${serviceId}:${titleId}`]: episodes
        }));
    }, [setEpisodeObject]);

    const secureFetch = useCallback(({serviceId, titleId}:{serviceId: string, titleId: string})=>{
        if (episodeKeyList.includes(`${serviceId}:${titleId}`)){
            return;
        }
        setEpisodeKeyList([...episodeKeyList, `${serviceId}:${titleId}`]);
        fetch({serviceId,titleId});
    }, [episodeKeyList, fetch, setEpisodeKeyList]);

    const refresh = useCallback(async ({serviceId, titleId}:{serviceId: string, titleId: string}) => {
        if (isRefreshing.current){
            return;
        }
        isRefreshing.current = true;
        if ( `${serviceId}:${titleId}` in episodeObject ){
            await fetch({serviceId,titleId});
            isRefreshing.current = false;
        }
    },[episodeObject, fetch]);

    const isNotExist = useCallback(({serviceId, titleId}:{serviceId: string, titleId: string}) => {
        return !(`${serviceId}:${titleId}` in episodeObject) && episodeKeyList.includes(`${serviceId}:${titleId}`)
    },[episodeKeyList, episodeObject]);

    return {fetch: secureFetch, refresh, isNotExist, episodeObject};
}