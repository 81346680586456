import React from 'react';
import { Button } from '@material-ui/core';
import { fn } from '../../../Functions';
import './Forbidden.scss'

const Forbidden = () => {
    return (
        <div className={'ForbiddenPage'}>
            <header>
                <a href="/" onClick={fn.gotoByAnchor}>
                    <picture>
                        <source media='(min-width: 480px)' srcSet='https://static.webtoon.today/ddah/icon/logo_webtly.svg'/>
                        <source srcSet='https://static.webtoon.today/ddah/icon/logo_w.svg'/>
                        <img src='https://static.webtoon.today/ddah/icon/logo_webtly.svg' alt="WebtlyLogo" />
                    </picture>
                </a>
            </header>
            <div className={"Content"}>
                <img src="https://static.webtoon.today/ddah/webtly/image/toggle_off.png" alt="toggle-off" width={'132px'} height={'64px'} />
                <div className={"TextBox"}>
                    <div className={"Title"}>
                        <span>{"해당 링크는 "}</span>
                        <br className={'ResponsiveLineBreak'}/>
                        <span>{"비활성화 상태입니다."}</span> 
                    </div>
                    <div className={"SubTitle"}>{"webtly로 단축 링크를 생성하고 공유할 수 있어요."}</div>
                </div>
                <Button className={'HomeButton'} href='/' onClick={fn.gotoByAnchor}>{"웹틀리 시작하기"}</Button>
            </div>
        </div>
    )
}

export default Forbidden;