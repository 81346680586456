import React, { ReactElement, useEffect, useRef, useState } from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import KakaoLogin from 'react-kakao-login';
import NaverLogin from './NaverLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLogin from './GoogleLogin';
import { Button, Popover } from '@material-ui/core';
import { useSignIn } from '../../../../../Recoil/Session/Session';
import { fn } from '../../../../../Functions';
import LoadingCircle from '../../../../../Component/LoadingCircle';
import { transferShortenerByOTPs } from '../../../../../Data/Shortner';

import './SNSLoginButtons.scss';

const Kakao = 'https://static.webtoon.today/challenge/KakaoIcon.png';
const Facebook = 'https://static.webtoon.today/challenge/facebook-1.png';

const FACEBOOK_CID="1115243009106744";

const KAKAO_CID="f8c19bb2397699a819edfddd8b03e402"

const GOOGLE_ID=(window.location.host.endsWith("webtoon.today") || window.location.host.endsWith("webt.ly"))
?"1093253062710-dh2542i4vf7soiqjji199vcr0i02d7vh.apps.googleusercontent.com"
:"646791605002-asu7bku1jtui8puu0nsuu47ef3pt83nb.apps.googleusercontent.com";

type platformType = 'google' | 'naver' | 'kakao' | 'facebook';

const SNSLoginButtons = ({
    displayedButtons,
    SNSCallback,
} : {
    displayedButtons: Set<platformType>,
    SNSCallback: Function,
}) => {
    const { GoogleSignin, NaverSignin, KakaoSignin, FacebookSignin } = useSignIn();
    
    const buttonsRef = useRef<{[key in platformType]: HTMLDivElement | null}>({
        google: null,
        naver: null,
        kakao: null,
        facebook: null
    });
    const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const getOnSuccess = (SNSSignIn: Function, type: platformType) =>
        (async (ssoInfo: any) => {
            setIsLoading(true);
        
            let newSession = await SNSSignIn(ssoInfo);
            
            if (newSession.memberState === 'regular') {
                localStorage.setItem('lastLogin', type);
                await transferShortenerByOTPs();
                
                fn.goto('/home');
            } else if (newSession.memberState === 'associate') {
                SNSCallback(newSession);
                fn.goto('/home/signin/personalsetting');
            }
            setIsLoading(false);
        })

    useEffect(() => {
        const lastLoginMethod = (localStorage.getItem('lastLogin') || '') as platformType | '';
        if(lastLoginMethod){
            setAnchorEl(buttonsRef.current[lastLoginMethod]);
        }

        const timeoutId = setTimeout(() => {
            setAnchorEl(null);
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        }
    }, [])
    

    let SNSButtons: ReactElement[] = [];
    if(displayedButtons.has('google')){
        SNSButtons.push(
            <div key={'google'} className="SNSButton" ref={el => {buttonsRef.current.google = el}}>
                <GoogleOAuthProvider clientId={GOOGLE_ID}>
                    <GoogleLogin onSuccess={getOnSuccess(GoogleSignin, "google")} />
                </GoogleOAuthProvider>
            </div>
        )
    }
    if(displayedButtons.has('naver')){
        SNSButtons.push(
            <div key={'naver'} className="SNSButton" ref={el => {buttonsRef.current.naver = el}}>
                <NaverLogin
                    onSuccess={getOnSuccess(NaverSignin, "naver")}
                />
            </div>
        )
    }
    if(displayedButtons.has('kakao')){
        SNSButtons.push(
            <div key={'kakao'} className="SNSButton" ref={el => {buttonsRef.current.kakao = el}}>
                <KakaoLogin
                    token = {KAKAO_CID}
                    onSuccess={getOnSuccess(KakaoSignin, "kakao")}
                    onFail={console.error}
                    onLogout={console.info}
                    render={({onClick}:{onClick: ()=>void}) => {
                        return(
                        <Button
                            onClick={(e)=>{
                                e.preventDefault();
                                onClick();
                            }} 
                            className={'Button'}
                            style={{
                                backgroundColor: 'rgb(254,229,0)',
                                color:'black',
                                height: '45px',
                                fontFamily: 'inherit',
                                textTransform: 'none'
                            }}
                        >
                            <img src={Kakao} alt={'KakaoLogo'} style={{maxHeight: '20px', position: 'absolute', left: '15px'}} />
                            {'Kakao 로그인'}
                        </Button>
                        )
                    }}
                />
            </div>
        )
    }
    if(displayedButtons.has('facebook')){
        SNSButtons.push(
            <div key={'facebook'} className="SNSButton" ref={el => {buttonsRef.current.facebook = el}}>
                <FacebookLogin
                    appId={FACEBOOK_CID}
                    scope={"public_profile,email,user_gender,user_birthday"}
                    onSuccess={getOnSuccess(FacebookSignin, "facebook")}
                    onFail={ async (error: any) => {
                        console.error(error)
                    }}
                    render={(renderProps: any) => (
                        <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled} 
                            className={'Button'}
                            style={{
                                backgroundColor: 'rgb(24, 119, 242)',
                                color:'white',
                                height: '45px',
                                fontFamily: 'inherit',
                                textTransform: 'none'
                            }}
                        >
                            <img src={Facebook} alt={'FacebookLogo'} style={{maxHeight: '20px', position: 'absolute', left: '15px', display: 'flex', alignItems: 'baseline'}} />
                            {'Facebook 로그인'}
                        </Button>
                    )}
                />
            </div>
        )
    }

    return (
        <div className="SNSLoginButtonsArea">
            {SNSButtons}
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                transformOrigin={{horizontal: 'center', vertical: 'top'}}
                elevation={0}
                PaperProps={{style: {backgroundColor: 'transparent'}}}
                style={{pointerEvents: 'none', top: '-14px'}}
            >
                <img src="https://static.webtoon.today/ddah/webtly/image/notice_recent_login.png" alt="마지막 로그인" width={'237px'} height={'54px'} />
            </Popover>
            <LoadingCircle show={isLoading} />
        </div>
    );
};

export default SNSLoginButtons;