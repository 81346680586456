import { useEffect, useRef } from "react";
import { randomString } from "../Functions";

const SVGComponent = ({ src, className, color, backgroundColor, stroke }:{ src: string, className?: string, color?: string, backgroundColor?: string, stroke?: string }) => {

    const uniqueId = useRef(randomString(16));
    let svgRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const fetchSvg = async () => {

            try {
                const response = await fetch(src);
                const svgText = await response.text();
                const parser = new DOMParser()
                let parsedSvg = parser.parseFromString(svgText, 'image/svg+xml').querySelector('svg');

                if (parsedSvg && svgRef.current) {
                    svgRef.current.appendChild(parsedSvg);
                }
                
            } catch (error) {
                console.error('Failed to fetch SVG:', error);
            }
        };
    
        fetchSvg();

        const currentRef = svgRef.current;
        return () => {
            if (currentRef) {
              while (currentRef.firstChild) {
                currentRef.removeChild(currentRef.firstChild);
              }
            }
        };
    }, [src]);
    
    useEffect(() => {
        if ( svgRef.current && svgRef.current.querySelector('svg') ) {
            svgRef.current.querySelector('svg')?.setAttribute("style", `fill: ${backgroundColor}`);
        }
    },[backgroundColor])

    useEffect(() => {
        if ( svgRef.current && svgRef.current.querySelector('path') && color ) {
            for ( const path of svgRef.current.querySelectorAll('path') ) {
                path.setAttribute("style", `fill: ${color}`);
            }   
        }
    },[color])

    useEffect(() => {
        if ( svgRef.current && svgRef.current.querySelector('path') && stroke ) {
            for ( const path of svgRef.current.querySelectorAll('path') ) {
                path.setAttribute("style", `stroke: ${stroke}`)
            }   
        }
    },[stroke])

    
    return (<div key={uniqueId.current} ref={svgRef} className={className} style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}} />)
}

export default SVGComponent;