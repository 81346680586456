import axios from "axios";
import ss from './Session';

export type searchResultType = {
    serviceId: string, titleId: string,
    title:string, thumbnail: string, onelineDescription: string, description: string,
    subscriptionCount: number, createdAt: number, updatedAt: number,
    lastEpisodeUpdatedAt: number|null, name: string
}

export const searchByTitle = async (title: string) : Promise<searchResultType[] | null>=> {
    if (!title){
        return null;
    }

    try {
        let res = await axios.get(`https://challenge-api.webtoon.today/market/search?type=title&query=${title}`,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }            
        });

        if ( res.data.code === 200 && res.data.data) {
            return res.data.data;
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}