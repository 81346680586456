import React from 'react';
import SNSLoginButtons from './Components/SNSLoginButtons';
import moment from 'moment-timezone';

import './Home.scss'

const Home = ({setUserInfo} : {setUserInfo: React.Dispatch<React.SetStateAction<UserInfoType>>}) => {
    return (
        <div className="SignInHome">
            <div className="SignInHomeContent">
                <div className="SignInHomeContentHeader">
                    <div className="Title">
                        {'로그인'}
                    </div>
                    <div className="Subtitle">
                        {'SNS계정으로 webtly를 이용해보세요!'}
                    </div>
                </div>
                <SNSLoginButtons
                    displayedButtons={new Set(['google', 'naver', 'kakao', 'facebook'])}
                    SNSCallback={(newSession: any) =>
                        setUserInfo(prev => ({
                            ...prev,
                            email: newSession.email || '',
                            name: newSession.name || '',
                            gender: newSession.gender,
                            birthday: moment(newSession.birthday || '').format('YYYY-MM-DD'),
                    }))}
                />
            </div>
        </div>
    );
};

export default Home;