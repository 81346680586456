import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import './InfoIconContainer.scss';

const InfoIconContainer = ({descriptionSrc, descriptionStyle}: {descriptionSrc: string, descriptionStyle?: CSSProperties}) => {
    const [isInfoShown, setIsInfoShown] = useState(false);
    const infoBoxRef = useRef<HTMLImageElement>(null);

    const showInfo = () => {
        setIsInfoShown(true);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (infoBoxRef.current && !infoBoxRef.current.contains(event.target as Node)) {
                setIsInfoShown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <span className='InfoIconContainer'>
            <span
                className='InfoIconBox'
                onClick={showInfo}
            >
                <img src="https://static.webtoon.today/ddah/marketinglanding/icon-information.svg" alt="정보"/>
            </span>
            {isInfoShown && 
                <img src={descriptionSrc} alt="설명" className='Description' ref={infoBoxRef} style={descriptionStyle}/>
            }
        </span>
    );
}

export default InfoIconContainer;