import React, { useEffect, useMemo, useState } from 'react';
import { BranchFunction, fn } from '../../../Functions';
import PersonalSetting from './PersonalSetting/PersonalSetting';
import Home from './Home/Home';
import NaverCallback from './Home/Components/NaverCallback';

import './index.scss';
import { useSession, useSignIn } from '../../../Recoil/Session/Session';
type pages = (
    'home'|
    'personalsetting'|
    'navercallback'
);

const SignIn = ({path, query}:{path: string, query: string}) => {

    const {session} = useSession();
    const {logout} = useSignIn();

    const [ userInfo, setUserInfo ] = useState<UserInfoType>({
        email: '',
        name: '',
        gender: undefined,
        birthday: '',
    });

    useEffect(()=>{
        if (path === '/signin/logout') {
            logout();
            fn.goto('/');
            return;
        }

        if(session.memberState === 'regular') {
            fn.replaceWith('/home');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path, session])

    const currentBranch = useMemo(() => BranchFunction<pages, string>(
        path,[
            {
                validator: (path) => /^\/signin\/personalsetting/i.test(path),
                defaultValue: 'personalsetting',
            },
            {
                validator: (path) => /^\/signin\/navercallback/i.test(path),
                defaultValue: 'navercallback',
            },
        ],
        'home'
    ), [path]);

    let body;
    if(currentBranch === 'personalsetting'){
        body = <PersonalSetting userInfo={userInfo} setUserInfo={setUserInfo} />;
    } else if(currentBranch === 'navercallback'){
        body = <NaverCallback />;
    } else{
        body = <Home setUserInfo={setUserInfo}/>;
    }

    return (
        <div className="SignIn">
            <SignInHeader />
            {body}
        </div>
    )
};

const SignInHeader = () => {
    return(
        <div className="SignInHeader">
            <a href='/' onClick={fn.gotoByAnchor}>
                <img src="https://static.webtoon.today/ddah/icon/logo_webtly.svg" alt="Webtly" width={'142px'} height={'38px'} />
            </a>
        </div>
    )
}

export default SignIn;