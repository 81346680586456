import React from 'react';

import "./LoadingCircle.scss";
import { createPortal } from 'react-dom';
import { flags } from '../Functions';

const LoadingCircle = ({show}: { show: boolean; }) => {

    if ( !flags.isClient ){
        return <></>;
    }

    return (createPortal(<div className={`LoadingCircle Animated ${show?'Show':''}`} style={{width: '100vw', height: '100vh'}}>
        <div className='Holder Bottom'><div className={"Circle"}/></div>
        <div className='Holder Left'  ><div className={"Circle"}/></div>
        <div className='Holder Top'   ><div className={"Circle"}/></div>
        <div className='Holder Right' ><div className={"Circle"}/></div>
        <div className='Holder Center'><div className={"Circle"}/></div>
    </div>,document.body));
}

export default LoadingCircle;