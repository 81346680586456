import { useEffect, useMemo, useState } from "react";

import './index.scss';
import '../Link/LinkCard.scss';

import LoadingCircle from "../../../Component/LoadingCircleAnimated";
import { getShortLinkStat, MarketingLinkType } from "../../../Data/Shortner";
import LinkChart from "./Components/LinkChart";
import { useShortners } from "../../../Recoil/Shortner/Shortner";
import OneColumnFullpageDialog from "../../../Component/OneColumnFullpageDialog";
import { LinkCard } from "../Link/Link";

const DashBoard = ({hash} : {hash?: string}) => {

    const {shortnerList, fetch} = useShortners();
    const [isLoading,] = useState(false);

    useEffect(()=>{
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const path = decodeURIComponent(hash?.replace(/^#/,'') || "");
    const [shortLinkStat, setShortLinkStat] = useState<MarketingLinkType>();

    const shortLink = useMemo(()=> shortnerList.find(row => row.path === path), [path, shortnerList]);
    console.log(path, shortLink)
    
    useEffect(()=>{
        const path = decodeURIComponent(hash?.replace(/^#/,'') || "");

        (async()=>{
            let stats = await getShortLinkStat(path);

            if (stats){
                setShortLinkStat(stats);
            }

            console.log(stats)
        })();
    },[hash])

    if (!shortLink) {
        return <div className={'WebtoonDashboardArea'}><OneColumnFullpageDialog>
            {"단축 링크 정보가 없어요!"}
        </OneColumnFullpageDialog></div>
    }

    return( <div className={'WebtoonDashboardArea'}>
                <LinkCard link={shortLink}/>
                {shortLinkStat && <LinkChart data={shortLinkStat} date={[]} />}
                <LoadingCircle show={isLoading} />
            </div>
    )
}

export default DashBoard;