import moment from 'moment-timezone';
import { logBehavior } from './Data/Behavior';
import { transferShortLink } from './Data/Shortner';

export const pixelize = (rem: number): number => (
    rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize || '16')
);

type point = {
    x: number, y: number
}
type size = {
    width: number, height: number
}

export const gridAllocation = (total: number, index: number, origin: point, size: size, padding: number): {left: number, top: number, padding: number} & size => {
    /** @type {point} */
    
    let grids = [];

    let item_in_a_row = Math.ceil(Math.sqrt(total))
    let center = {x: Math.floor(item_in_a_row/2), y: Math.floor(item_in_a_row/2)};
    
    for (let i = 0; i < item_in_a_row; i++){
        for(let j = 0; j < item_in_a_row; j++){
            grids.push({
                distance: Math.pow(i - center.x, 2) + Math.pow(j - center.y, 2),
                point: {x: i - center.x, y: j - center.y}
            })
        }
    }

    let sorted_grids = grids.sort((a,b) => a.distance - b.distance);
    let cssPoint = {
        left: origin.x + sorted_grids[index].point.x * (size.width + padding),
        top: origin.y + sorted_grids[index].point.y * (size.height + padding)
    }
    
    return { ...cssPoint, ...size, padding };
}

type branchLogicType<T,KeyType> = {
    validator: (Object: KeyType) => boolean,
    sublogic?: branchLogicType<T, KeyType>[],
    defaultValue: T, 
}

export const BranchFunction = <T,KeyType>(key: any, logic: branchLogicType<T, KeyType>[], defaultValue: T): T => {

    if (logic instanceof Array){
        for(const {validator, sublogic, defaultValue} of logic){
            if (validator(key)){
                if (sublogic && sublogic instanceof Array && sublogic.length > 0){
                    return BranchFunction(key, sublogic, defaultValue);
                }else{
                    return defaultValue;
                }
            }else {
            }
        }
    }
    return defaultValue;

}

export const randomString = (length: number): string =>
  new Array(length).fill(0)
    .map((x) => String.fromCharCode(65 + Math.floor(Math.random() * 2) * 32 + Math.floor(Math.random() * 26)))
    .join("");

export const fn = {
    goto: (event: string | null | undefined)=>{},
    replaceWith: (event: string | null | undefined)=>{},
    gotoByAnchor: (event: any)=> {
        event.preventDefault(); 
        /**
         * @type {HTMLElement}
         */
        let target = event.target
        
        while (target && target.tagName !== 'body' && !target.getAttribute('href')){
            target = target.parentElement
        }
        if (target.tagName === 'body'){
            return;
        }else{
            fn.goto(target.getAttribute('href'));
        }
    },
    alert: window.alert,
};


type urlString = string;

export const thumbnailize = (url:urlString, max_width=0,max_height=0): urlString => (
    !url
        ?url
        :`https://cached-api.webtoon.today/thumb?u=${encodeURIComponent(url)}&agent${max_width>0?`&mw=${max_width*2}`:''}${max_height>0?`&mh=${max_height*2}`:''}`
);

export const getOffsetInScreen = (DOM: HTMLElement): ({top: number, left: number, height: number, width: number}|{}) => {
    if (!DOM){
        return {};
    }
    return DOM.getBoundingClientRect();
}

export const dateFormat = (date: Date): string =>{
    let todayZeroAM = new Date();
    todayZeroAM.setHours(0,0,0,0);

    const todayZeroUnixTime = todayZeroAM.getTime();
    const unixDate = date.getTime();

    if (todayZeroUnixTime - unixDate < 2 * 24 * 60 * 60 * 1000){
        if (todayZeroUnixTime - unixDate < 0 ){
            return `오늘`;
        }else if (todayZeroUnixTime - unixDate < 1 * 24 * 60 * 60 * 1000){
            return `어제`;
        } else {
            return `이틀 전`;
        }
    }
    
    let thisMonthFirst = new Date();
    thisMonthFirst.setHours(0,0,0,0);
    thisMonthFirst.setDate(1);

    if (todayZeroUnixTime - unixDate < (365 - 31) * 24 * 60 * 60 * 1000){
        return `${('00'+(date.getMonth()+1)).substr(-2)}/${('00'+date.getDate()).substr(-2)}`
    }

    return `${date.getFullYear()}`;

}

export const decodeEntities = (str: string | null): string | null => {
        // this prevents any overhead from creating the object each time
        let element = document.createElement('div');
    
        const decodeHTMLEntities = (str: string | null): string | null => {
            if(str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }
        
            return str;
        }
    
        return decodeHTMLEntities(str);
};

export const encodeEntities = (str: string): string => {
    const encodedStr = str.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';');

    return encodedStr;
}

export const unique = <T>(obj: T, index: number, arr: T[]): boolean => {
    if (index === arr.indexOf(obj)){
        return true;
    }else {
        return false;
    }
}

export const waitImageLoaded = (imageUrl: string): Promise<{width: number, height: number}> => {
    let smallImage = document.createElement('img');
    smallImage.setAttribute('style', 'width:1px; opacity:0; position:fixed; top: 0');
    const imagePromise = new Promise((resolve,reject) => {
        smallImage.onload = () => {
            resolve({width: smallImage.naturalWidth || 16, height: smallImage.naturalHeight || 9})
            document.body.removeChild(smallImage)
        }
        smallImage.onerror = (error) => {
            reject(error)
            document.body.removeChild(smallImage)
        }
    })
    document.body.appendChild(smallImage);
    smallImage.setAttribute('src', imageUrl);

    return imagePromise as Promise<{width: number, height: number}>;
}

export const parseUrl = (url: string): ({
    fullUrl: urlString, scheme: `http${'s'|''}`, domain: string, path: string, query: {[key: string]: any}, hash: string
}|null) => {
    if (! (/(https|http):\/\/([^/]+)(\/?[^?]+)?\??([^#]+)?(#?.+)?$/.test(url)) ){
        return null;
    }
    const [fullUrl, scheme, domain, path, query, hash] = (
        /^(https|http):\/\/([^/]+)(\/?[^?]+)?\??([^#]+)?(#?.+)?$/.exec(url) || ['', 'http', '', '', '', '']
    ) as [string, `http${'s'|''}`, string, string, string, string];

    return {
        fullUrl, scheme, domain, path, query: Object.fromEntries(query.split('&').map(pair => pair.split('=').map(term => decodeURIComponent(term)))), hash
    }
}

/**
 * @description {@link https://gist.github.com/flyskyne/b6b310187ec26581f7ee548656473e72}의 오픈소스를 참고하였습니다.
 */
export const koreanFirstSort = (a: string, b: string): -1 | 0 | 1 => {

    const addOrderPrefix = (text: string): string => {
            
        const code = text.toLowerCase().charCodeAt(0);
        let prefix = "";

        if (0xac00 <= code && code <= 0xd7af) {prefix = '1'} 
        else if (0x3130 <= code && code <= 0x318f) {prefix = '2'} 
        else if (0x61 <= code && code <= 0x7a) {prefix = '3'} 
        else {prefix = '9'}

        return prefix + text;
    }
    
    a = addOrderPrefix(a);
    b = addOrderPrefix(b);

    if (a < b) {return -1}
    if (a > b) {return 1}
    return 0;
}

/**
 * @description {@link https://taegon.kim/archives/9919}의 오픈소스를 참고하였습니다.
 */
const syllablePattern = (syllable: string): string => {
    const offset = 44032;

    if(/[가-힣]/.test(syllable)){
        const syllableCode = syllable.charCodeAt(0) - offset;

        if ( syllableCode % 28 > 0 ) {
            return syllable;
        }

        const begin = Math.floor(syllableCode / 28) * 28 + offset;
        const end = begin + 27;
        return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }

    if (/[ㄱ-ㅎ]/.test(syllable)) {

        const vowelToSyllable: {[key: string]: number} = {
            ㄱ: "가".charCodeAt(0),
            ㄲ: "까".charCodeAt(0),
            ㄴ: "나".charCodeAt(0),
            ㄷ: "다".charCodeAt(0),
            ㄸ: "따".charCodeAt(0),
            ㄹ: "라".charCodeAt(0),
            ㅁ: "마".charCodeAt(0),
            ㅂ: "바".charCodeAt(0),
            ㅃ: "빠".charCodeAt(0),
            ㅅ: "사".charCodeAt(0)
        };

        const begin =
            vowelToSyllable[syllable] ||
            (syllable.charCodeAt(0) - 12613) * 588 + vowelToSyllable["ㅅ"];
        const end = begin + 587;

        return `[${syllable}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }

    return syllable;
}

/**
 * @description {@link https://taegon.kim/archives/9919}의 오픈소스를 참고하였습니다.
 */
export const createFuzzyMatcher = (input: string): RegExp => {
    const pattern = '(.*)?'+input.split('').map(character => { return '('+syllablePattern(character)+')' }).join('(.*)?')+'(.*)?';
    
    return new RegExp(pattern);
}

/**
 * 
 * @param {string} pattern fuzzy pattern
 * @param {string} target string to calculate distance from pattern
 * @returns distance of target from pattern
 */
export const computeFuzzyDistance = (pattern: string, target: string) => {
    let matched = createFuzzyMatcher(pattern).exec(target)

    if (!matched){
        return -1;
    }

    let distance = 0
    for (let i = 1; i< pattern.length * 2; i+=2){
        distance += (matched[i] || '').length * Math.pow(10, -Math.floor(i/2))
    }

    return distance;
}


export const validateEmailForm = (infomation: string): boolean => {
        
    var regExp = /^([-_.+0-9a-zA-Z])*@([-_.0-9a-zA-Z])*\.[a-zA-Z]{2,10}$/i;

    if (regExp.test(infomation)) {

        return true;

    } else {

        return false;
    }
}

export const timeLapsConversion = (commentTime: number): string | undefined => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLag = currentTime - commentTime;

    if ( 24 * 60 * 60 <= timeLag) {
        return moment(commentTime * 1000).format('YY.MM.DD');
    };

    if ( timeLag < 60 ) {
        const timeLagSeconds = Math.floor(timeLag);
        return `${timeLagSeconds}초전`
    };

    if ( 60 <= timeLag && timeLag < 60 * 60) {
        const timeLagMinutes = Math.floor(timeLag / 60 );
        return `${timeLagMinutes}분전`;
    };

    if ( 60 * 60 <= timeLag && timeLag < 24 * 60 * 60 ){
        const timeLagHours = Math.floor(timeLag / (60 * 60) )
        return `${timeLagHours}시간전`
    };
};

export const checkKorean = (password: string): boolean => {
    const regx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    return regx.test(password)
}

export const isAgeAdult = ( birthday: Date ): boolean => {
    const isAdult = ((new Date()).getFullYear() >= (new Date(birthday).getFullYear() + 19))
    return isAdult;
}
export const addCommaToNumber = (number: number): string => {
    if(typeof number !== 'number'){
        return "N/A";
    }else{
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}


export const numberToAccountingNumber = (num: number | string): string => {

    if (Number.isNaN(Number(num))){
        return "NaN";
    }

    let [ integer, decimal = '' ] = num.toString().split('.');
    let negative = integer.startsWith('-');
    if (negative) {
        integer = integer.substring(1);
    }

    return `${
        negative?'-':''
    }${
        integer.split('').reverse().map((char, idx) => `${char}${idx % 3 === 0 && idx !== 0 ?',':''}`).reverse().join('')
    }${
        decimal?'.':''
    }${
        decimal
    }`
}

const specialCharacters = new Set(['\r']);
const CSVTokenizer = (csv: string) => {
    let res = [];

    let escaped = false;
    let currentRow = [];
    let currentToken = "";
    for (let i=0; i<csv.length; i++) {
        let char = csv[i];

        if (char === ',' && !escaped){
            try{
                currentRow.push(JSON.parse(`"${currentToken}"`));
            }catch(e){
                currentRow.push(currentToken);
            }
            currentToken = '';
            continue;
        }

        if (char === '\n' && !escaped){
            currentRow.push(JSON.parse(`"${currentToken}"`));
            res.push(currentRow);
            currentToken = '';
            currentRow = [];
            continue;
        }

        if (char === '"'
            && (i < 1 || csv[i-1] !== '\\' )
            && (i < 2 || csv[i-1] !== '\\' || csv[i-2] !== '\\' )
        ){
            escaped = !escaped;
            continue;
        }

        if (specialCharacters.has(char)){
            continue;
        }

        currentToken += char;
    }

    if (currentToken){
        currentRow.push(JSON.parse(`"${currentToken}"`));
    }

    if (currentRow.length > 0){
        res.push(currentRow);
    }

    return res;
}

export const readCSVAsNamedRecordArray = (csv: string, hasHeader: boolean = true) => {
    let data = CSVTokenizer(csv);

    let header = Array(data.map(row => row.length).reduce( (a,b) => a>b?a:b, 0 )).fill(null).map( (v,i) => `c_${i+1}`)
    if (hasHeader){
        header = header.map( (v,i) => (i<data[0].length?data[0][i]:v).trim() );
        data = data.slice(1);
    }

    return data.map(row => Object.fromEntries(
        row.map((col,index) => [header[index], col.trim()])
    ))
}
export const fileReadHandler = async (file: File, encoding: 'dataurl'|'utf8' = 'utf8'): Promise<string|ArrayBuffer|null> => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        
        reader.onload = (event) => {
            return resolve(reader.result);
        };

        reader.onerror = (event) => {
            if (reader.error){
                return reject(reader.error);
            }
        };

        if (encoding === 'utf8'){
            reader.readAsText(file);
        }else if (encoding === 'dataurl'){
            reader.readAsDataURL(file);
        }
    })
}

export const flags:{
    didTitleUpdated: boolean,
    didEpisodeUpdated: boolean,
    didPasswordReset: boolean,
    isClient: boolean,
} = {
    didTitleUpdated: false,
    didEpisodeUpdated: false,
    didPasswordReset: false,
    isClient: false,
};

/**
 * 
 * @returns true if two are same, false if not.
 */
export const deepCompareObjects = (lhs: any, rhs: any): boolean => {

    if (lhs === null || lhs === undefined || rhs === null || rhs === undefined){
        return lhs === rhs;
    }

    // TODO: Deep comparision of two objects V
    if (typeof lhs === 'object' && typeof rhs === 'object') {
        const keys1 = Object.keys(lhs);
        const keys2 = Object.keys(rhs);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (!deepCompareObjects(lhs[key], rhs[key])) {
                return false;
            }
        }
        return true;
    } else {
        return lhs === rhs;
    }
}

export const removeTrailingSlash = (url: string) => {
    if (url.endsWith('/')) {
        return url.slice(0, -1);
    }
    
    return url;
}

export const filterUrlByKeys = ( url: string, keys: string[] ) => {
    let urlArray = removeTrailingSlash(url).split('/');

    if (keys.includes(urlArray[urlArray.length - 1])) {
        urlArray.pop();
    }
    
    var newUrl = urlArray.join('/');
    
    return newUrl;
}

export const capitalizeFirstLetter = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const copyClipboard = async (
    text: string,
    successAction?: () => void,
    failAction?: () => void,
) => {
    try {
        await navigator.clipboard.writeText(text);
        successAction && successAction();
    } catch (error) {
        failAction && failAction();
    }
};

export const sharingPage = async ({
    title, text, url, log,
}: {
    title: string, text: string, url: string,
    log: {what: string, detail: object}
}) => {
    try{
        await navigator.share({
            title, url, text,
        });

        if (log.what) {
            logBehavior(log.what, log.detail);
        };
    } catch (e: any) {
        if (!e.includes("AbortError")){
            alert(`Error: ${e}`);
        };
    };
}
