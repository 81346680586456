import React from 'react';
import { Button } from '@material-ui/core';
import { fn } from '../../../Functions';
import './NotFound.scss';

const NotFound = () => {
    return (
        <div className={'NotFoundPage'}>
            <header>
                <a href="/" onClick={fn.gotoByAnchor}>
                    <picture>
                        <source media='(min-width: 480px)' srcSet='https://static.webtoon.today/ddah/icon/logo_webtly.svg'/>
                        <source srcSet='https://static.webtoon.today/ddah/icon/logo_w.svg'/>
                        <img src='https://static.webtoon.today/ddah/icon/logo_webtly.svg' alt="WebtlyLogo" />
                    </picture>
                </a>
            </header>
            <div className={"Content"}>
                <img src="https://static.webtoon.today/ddah/webtly/image/pulled_plug.png" alt="pulled-plug" width={'218px'} height={'96px'} />
                <div className={"TextBox"}>
                    <div className={"Title"}>
                        <span>{"주소를 잘못 입력하였거나, "}</span>
                        <br className={'ResponsiveLineBreak'}/>
                        <span>{"변경 혹은 삭제되어"}</span>
                        <br />
                        <span>{"요청하신 페이지를 찾을 수 없어요."}</span> 
                    </div>
                    <div className={"SubTitle"}>{"입력하신 주소가 정확한지 다시 한 번 확인해 주세요."}</div>
                </div>
                <Button className={'HomeButton'} href='/' onClick={fn.gotoByAnchor}>{"웹틀리 시작하기"}</Button>
            </div>
        </div>
    )
}

export default NotFound;