import axios from "axios";
import ss from './Session';


type StatusType = 'ACTIVE' | 'PAUSED';

type ShortenerType = {
    name: string,
    path: string,
    episodeId?: string,
    serviceId?: string,
    titleId?: string,
    destination?: string,
}

export type ChartDataType = {[key: string]: number};

export type MarketingLinkType = {
    [key: string]: {
        os: {[key: string]: ChartDataType},
        osRef: {[key: string]: ChartDataType},
        referrer: {[key: string]: ChartDataType},
        total: ChartDataType
    }
};


export type ShortLinkType = ShortenerType & {status: StatusType, createdAt: number};

export const getShortLinkList = async (): Promise<ShortLinkType[] | null> => {
    try {
        let res = await axios.get(`https://challenge-api.webtoon.today/webtly/shortener`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.status === 200){
            return res.data.data;
        }else{
            return null;
        }
    } catch (e) {
        console.error(e)
        return null;
    }
}

export const getShortLinkStat = async (path: string): Promise<MarketingLinkType|null> => {
    try {
        let res = await axios.post(`https://challenge-api.webtoon.today/webtly/shortener`, {path}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.status === 200){
            return res.data.data;
        }else{
            return null;
        }
    } catch (e) {
        console.error(e)
        return null;
    }
}

export const createShortnerLink = async ( newLinkInfo: ShortenerType ): Promise<{otp: string |null, url: string} |null> => {
    try {
        let res = await axios.put('https://challenge-api.webtoon.today/webtly/shortener', newLinkInfo, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if ( res.data && res.data.code === 200 ) {
            return res.data.data;
        } else {
            return null
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}

export const deleteShortLink = async (path: string) => {
    try {
        let res = await axios.delete(`https://challenge-api.webtoon.today/webtly/shortener?path=${encodeURIComponent(path)}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res && res.data && res.status === 200) {
            return res.data.data;
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}

export const updateShortLink = async ({
    serviceId, titleId, episodeId, 
    path, name, destination
}:{
    serviceId?: string, titleId?: string, episodeId?: string |null, 
    path: string, name: string, destination?: string
}) => {
    try {
        let res = await axios.patch(`https://challenge-api.webtoon.today/webtly/shortener`, {
            serviceId, titleId, name, 
            destination, episodeId, path
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res && res.data && res.status === 200) {
            return res.data.data;
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}

export const transferShortLink = async (otp: string) => {
    try {
        let res = await axios.patch(`https://challenge-api.webtoon.today/webtly/shortener/ownership`, {otp}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res && res.data && res.status === 200) {
            return res.data.data;
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}


export const transferShortenerByOTPs = async () => {
    const OTPsInStorage = localStorage.getItem("shortLink-otp");
    if ( OTPsInStorage ) {
        const parsedOTPs = JSON.parse(OTPsInStorage);
        if (!parsedOTPs.isArray() || parsedOTPs.length < 1){
            return;
        }

        await Promise.all(parsedOTPs.map( async (otp: string) => {
            await transferShortLink(otp);
        }));

        localStorage.removeItem("shortLink-otp");
    }
}