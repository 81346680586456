
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import './LinkDrawerDialog.scss';
import { CSSProperties } from 'react';

const BasicDialog = ({ 
    open, onClose = () => {},
    title, content, buttons = []
}:{ 
    open: boolean, onClose: () => void,
    title: string, content: string, 
    buttons: {
        className: string,
        text: string,
        onClick?: () => void,
        style?: CSSProperties,
    }[]
    
}) => {

    return(<Dialog className={'BasicDialog'} open={open} onClose={onClose} PaperProps={{className: 'BasicDialogPaper'}} >
        <DialogTitle className={'BasicDialogTitle'} >
            <div className={'TitleText'} >
                {title}
            </div>
        </DialogTitle>
        <DialogContent className={'BasicDialogContent'} >
            {content}
        </DialogContent>
        <DialogActions className={'BasicDialogActions'} >
            {buttons.map( ({className, text, onClick, style}) => (
                <Button
                    className={`BasicDialogButton ${className}`}
                    key={`${text}`}
                    style={style}
                    onClick={(e) => {
                        if ( onClick ) {
                            onClick();
                        }
                    }}
                    fullWidth
                >
                    {text}
                </Button>
            ))}
        </DialogActions>
    </Dialog>)
}

export default BasicDialog;

