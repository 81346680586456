
import axios from 'axios';
import ss from './Session';

export const getTitle = async (serviceId: string, titleId: string): Promise<TitleType[]> => {
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/market/title', {key : [serviceId, titleId].join(':')}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.data;

        } else {
            return [];
        };

    }catch(e){
        if ((e as any).response && (e as any).response.status === 404) {
            console.log('404 Error')
            return [];
        }
        return [];
    };
};

export const getTitles = async (keys: {serviceId: string, titleId: string}[]): Promise<TitleType[]> => {
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/market/title', {key : keys.map(({serviceId, titleId})=>[serviceId, titleId].join(':')).join(",")}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return [];
        };

    }catch(e){
        return [];
    };
};