import React, { ReactNode, useCallback } from 'react';

import { useSession } from './Recoil/Session/Session';
import {logBehavior} from './Data/Behavior';

import {BranchFunction, fn} from './Functions';


import './VirtualRouter.scss';

import Home from './Route/Home';
import Landing from './Route/Landing/Landing';
import NotFound from './Route/Home/NotFound/NotFound';
import Forbidden from './Route/Home/Forbidden/Forbidden';

const jsxBranch = (path: string, query: string, hash: string, newProps: any) => BranchFunction<()=>ReactNode, {path: string, query?: string, hash?: string, newProps?: any}>(
    {path},
    [
        {
            validator: ({path}) => /^\/home\/notfound/i.test(path),
            defaultValue: () => <NotFound />,
        },
        {
            validator: ({path}) => /^\/home\/forbidden/i.test(path),
            defaultValue: () => <Forbidden />,
        },
        {
            validator: ({path}) => /^\/home/i.test(path),
            defaultValue: () => <Home path={path} query={query} hash={hash} />
        },
        {
            validator: ({path}) => path === "/",
            defaultValue: () => <Landing />
        },
    ],
    () => <Landing />,
)

const VirtualRouter = (props: {
    isClient: boolean, injectedLocation?: string,
}) => {
    const { session } = useSession();
    
    const [path, setPath] = React.useState((props.injectedLocation || window.location.pathname).replace(window.location.origin || '',''));
    const [query, setQuery] = React.useState('?' + (props.injectedLocation?.split('?')[1]?.split('#')[0] || window.location.search.slice(1)));
    const [hash, setHash] = React.useState((props.injectedLocation || '').split('#')[1] || window.location.hash);

    React.useEffect(()=>{
        const historyListener = ()=>{
            setPath(window.location.pathname);
            setQuery(window.location.search);
            setHash(window.location.hash);
        };
        window.addEventListener('popstate', historyListener);
        return ()=> {
            window.removeEventListener('popstate', historyListener);
        }
    },[])

    const handleUrlOrPath = useCallback((stateHandling:any) => (urlOrPath?: string | null) => {
        if (urlOrPath === window.location.pathname){
            return;
        }
        logBehavior('goto', {to: urlOrPath});

        if (urlOrPath === undefined || urlOrPath === null) {
            window.history.back();
        }else if (urlOrPath.startsWith("/")){
            if ('gtag' in window){
                (window as any).gtag('config', "G-8PRR5WLG9M", {'page_path': urlOrPath});
            }
            
            const path = urlOrPath.split('#')[0];
            const hash = urlOrPath.split('#')[1];
            
            if (path.startsWith("/") || path === ""){
                setPath(path);
                setQuery('?' + (path.split('?')[1] || ''));
                setHash(hash);

                if(stateHandling === 'push'){
                    window.history.pushState({}, document.title, window.location.origin + path + (hash?`#${hash}`:``));
                } else{
                    window.history.replaceState({}, document.title, window.location.origin + path + (hash?`#${hash}`:``));
                }
            }
        }else {
            window.open(urlOrPath);
        }
    }, []);

    const goto = handleUrlOrPath('push');
    const replaceWith = handleUrlOrPath('replace');

    React.useEffect(()=>{
        fn.goto = goto;
        fn.replaceWith = replaceWith;
    },[goto, replaceWith]);

    const newProps = {
        isClient: props.isClient,
        goto, path,
        session: session
    };

    return (
    <>
        <div className={"Router"}>
            <div style={{width:'100%'}}>
                {jsxBranch(path, query, hash, newProps)()}
            </div>
        </div>
    </>
    );
}

export default VirtualRouter