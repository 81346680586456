import { unique } from "../../../../Functions";

export const numericUnitConverter = ( income: string | number, floatUnit?: number ): string => {
    const strIncome = `${income}`
    if (!income) return strIncome;

    const number: number = Number(income)
    if (!number) return strIncome;

    const units = [ '', '만', '억', '조', '경']
    const unitOfIncome = Math.floor(((strIncome || '').length - 1) / 4)
    const floatingPoint = typeof floatUnit === "number"?floatUnit:unitOfIncome;
    
    if ( (strIncome || "").length < 5 ) {
        return Number(income).toLocaleString();
    } else {
        return `${((number / (10000 ** unitOfIncome)).toFixed(floatingPoint)).replace(/\.0+$/,"")}${units[unitOfIncome]}`
    }
}

export const SummationOfDoubleDeepObject = (
    a: {[first: string]: {[second: string]: number}},
    b: {[first: string]: {[second: string]: number}},
) => {
    let ret: {[first: string]: {[second: string]: number}} = {};

    let entriesOfA = 
        Object.entries(a || {}).map(
            ([first, secondObj]) => (
                Object.entries(secondObj || {}).map(
                    ([second, value]) => ( [first, second, value] as const )
                )
            )
        ).flat()
        
    let entriesOfB = 
        Object.entries(b || {}).map(
            ([first, secondObj]) => (
                Object.entries(secondObj || {}).map(
                    ([second, value]) => ( [first, second, value] as const )
                )
            )
        ).flat()

    for (let [first, second, value] of entriesOfA){
        if (!(first in ret)) {
            ret[first] = {};
        }
        ret[first][second] = (ret[first][second] || 0) + value;
    }

    for (let [first, second, value] of entriesOfB){
        if (!(first in ret)) {
            ret[first] = {};
        }
        ret[first][second] = (ret[first][second] || 0) + value;
    }


    return ret;
}

export const makeDenseDoubleDeepObject = (
    target: {[first: string]: {[second: string]: number}},
    filler: any = 0
) => {
    let everyFirstKey = Object.keys(target).filter(unique);

    let everySecondKey = Object.values(target).map(obj => Object.keys(obj)).flat().filter(unique);

    let ret:{[first: string]: {[second: string]: number}} = {};

    for (const first of everyFirstKey) {
        if (!(first in ret)) {
            ret[first] = {};
        }
        for (const second of everySecondKey) {
            ret[first][second] = (target[first] || {})[second] || filler;
        }
    }

    return ret;
}

export const convertEmptyKeyToFiller = (
    target: {[first: string]: {[second: string]: number}},
    filler: string = "알수없음"
) => {
    let ret: {[first: string]: {[second: string]: number}} = {};

    let entries = 
        Object.entries(target || {}).map(
            ([first, secondObj]) => (
                Object.entries(secondObj || {}).map(
                    ([second, value]) => ( [first, second, value] as const )
                )
            )
        ).flat();

    let everyFirstKey = Object.keys(target).filter(unique);
    let everySecondKey = Object.values(target).map(obj => Object.keys(obj)).flat().filter(unique);
    if (everyFirstKey.includes(filler) || everySecondKey.includes(filler)) {
        filler += '(자동치환)';
    }
    
    for (let [first, second, value] of entries){
        first = first || filler;
        second = second || filler;
        if (!(first in ret)) {
            ret[first] = {};
        }
        ret[first][second] = value;
    }

    return ret;
}