import React, { useState } from "react";
import { NestedCascadeDrawer } from "@webtoontoday/drawer";

import './LinkCreationDrawer.scss';
import WithLabel from "../../../../Component/WithLabel";
import LandingAutocomplete from "../../../Landing/Components/LandingAutocomplete";
import { Button, TextField } from "@material-ui/core";
import TextCheckBox from "../../../../Component/TextCheckBox";
import { Close } from "@material-ui/icons";
import BasicDialog from "./LinkDrawerDialog";
import { ShortLinkType, createShortnerLink } from "../../../../Data/Shortner";
import LoadingCircle from "../../../../Component/LoadingCircleAnimated";
import { useToastAlert } from "@webtoontoday/toast";
import ChannelService from "../../../../Component/ChannelService";
import { randomString } from "../../../../Functions";
import { useTitles } from "../../../../Recoil/Title/Titles";
import PlatformInfo from './PlatformInfo';

const InputHeight = 40;

const CreateLink = ({ 
    open, onClose, onComplete,
    name: givenName, serviceId: givenServiceId, titleId: givenTitleId, episodeId: givenEpisodeId, 
    path: givenPath, destination: givenDestination
}:{ 
    open: boolean, 
    onClose: () => void,
    onComplete: (link: ShortLinkType) => void,
    name?: string |undefined;
    serviceId?: "kakao" | "naver" | "other" | undefined;
    titleId?: string |undefined;
    episodeId?: string |undefined;
    path?: string |undefined;
    destination?: string |undefined;
}) => {
    const { toastAlert  } = useToastAlert();
    const { titleObject } = useTitles();

    // 공통
    const [ name, setName ] = useState<string|undefined>(givenName);
    const [ path, setPath ] = React.useState<string|undefined>(undefined);
    const [ isRandomPath, setIsRandomPath ] = React.useState<boolean>(false);

    // naver, kakao
    const [ serviceId, setServiceId ] = React.useState<"kakao" | "naver" | "other" | undefined>(givenServiceId);
    const [ titleId, setTitleId ] = React.useState<string |undefined>(givenTitleId);
    const [ episodeId, setEpisodeId ] = React.useState<string |undefined>(givenEpisodeId);

    // 기타
    const [ destination, setDestination ] = React.useState<string |undefined>(givenDestination);

    const [ isExitDailogOpen, setIsExitDialogOpen ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const handleClose: () => void = () => {

        if (   givenName !== name 
            || givenPath?.split("/").at(-1) !== path
            || givenServiceId !== serviceId
            || givenTitleId !== titleId
            || givenDestination !== destination 
        ) {
            setIsExitDialogOpen(true);
        } else {
            onClose();
        }
    }


    let isAllFormed:boolean = false;
    if ( serviceId === 'other' ) {
        isAllFormed =   Boolean(serviceId) 
                    && (Boolean(path) || isRandomPath)
                    &&  Boolean(destination)
    } else {
        isAllFormed =   Boolean(serviceId) 
                    && (Boolean(path) || isRandomPath)
                    &&  Boolean(serviceId && titleId)
    }
    
    return(<NestedCascadeDrawer className={'LinkCreationDrawerArea'} open={open} onClose={handleClose} hasModal={true} >
        <div className={'LinkCreationInner'} >
            <div className={'LinkCreationHeader'} >
                <span className={'Title'}>{givenPath?"링크 만들기":"링크 생성"}</span>
                <Close  onClick={handleClose} />
            </div>
            <div className={'LinkCreationContents'} >
                <WithLabel label={'링크 타이틀(선택)'} contentStyles={{ backgroundColor: 'rgb(255,255,255)' }} >
                    <TextField
                        type={'text'} variant={'outlined'}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        placeholder={'링크 타이틀을 입력해주세요.'}
                        InputProps={{
                            style: {
                                height: InputHeight,
                            }
                        }}
                        key={'LinkCreationLinkName'}
                    />
                </WithLabel>
                <WithLabel label={'플랫폼'} contentStyles={{ backgroundColor: 'rgb(255,255,255)' }} >
                    <LandingAutocomplete
                        options={[ "네이버", "카카오", "기타" ]}
                        value={ serviceId?{"naver": "네이버","kakao": "카카오","other": "기타"}[serviceId]:undefined }
                        onChange={()=>setTitleId(undefined)}
                        onInputChange={serviceLabel => {
                            const labelToKey: {[key: string]: "naver" | "kakao" | "other"} = {
                                "네이버": "naver",
                                "카카오": "kakao",
                                "기타"  : "other",
                            }
                            setServiceId(serviceLabel?labelToKey[serviceLabel]:undefined);
                        }}
                        placeholder={"선택해주세요."}
                        InputProps={{
                            style: {
                                height: InputHeight
                            }
                        }}
                    />
                </WithLabel>
                {/* 플랫폼이 기타일 때, */}
                {serviceId === "other"
                ?<WithLabel label={'단축할 링크'} contentStyles={{ backgroundColor: 'rgb(255,255,255)' }} >
                    <TextField
                        type={'text'} variant={'outlined'}
                        defaultValue={path}
                        onChange={(e) => {
                            setDestination(e.target.value);
                        }}
                        placeholder={'단축할 링크를 입력해주세요.'}
                        InputProps={{
                            style: {
                                height: InputHeight,
                            }
                        }}
                        key={"linkeCreationSelectPlatform"}
                    />
                </WithLabel>
                // 플랫폼이 네이버 or 카카오일 때,
                :<PlatformInfo
                    key={serviceId}
                    serviceId={serviceId}
                    titleId={titleId}
                    episodeId={episodeId}
                    onSelectTitleId={(newTitleId: string |undefined) => setTitleId(newTitleId) }
                    onSelectEpisodeId={(newEpisodeId: string |undefined) => setEpisodeId(newEpisodeId) }
                    InputHeight={InputHeight}
                    contentStyles={{backgroundColor: 'rgb(255,255,255)'}}
                />}
                <hr style={{width: '100%', margin: 0, border: '1px solid rgba(236,237,243,1)'}} />
                <div className={'AddressGenerator'} >
                    <div className={'GeneratorInputBox'} >
                        <div className={'FixedAddress'}>
                            <div className={"Address"} style={{height: InputHeight}}>{"webt.ly"}</div>
                            <div className={"Slash"}>{"/"}</div>
                            <div className={"Address"} style={{height: InputHeight}} >{"random"}</div>
                            <div className={"Slash"}>{"/"}</div>
                        </div >
                        <WithLabel label={''} style={{width: '100%'}} contentStyles={{backgroundColor: 'rgb(255,255,255)'}} >
                            <TextField
                                type={'text'} variant={'outlined'}
                                value={path||""}
                                disabled={isRandomPath}
                                onChange={(e) => {
                                    setPath(e.target.value);
                                }}
                                placeholder={'단축 링크를 완성해보세요'}
                                InputProps={{
                                    style: {
                                        height: InputHeight,
                                    }
                                }}
                                style={{
                                    background: `${isRandomPath?"linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #FFF":"#FFF"}`,
                                }}
                            />
                        </WithLabel>
                    </div>
                    <div className={'RandomUrlCheckBox'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <TextCheckBox
                            text={'랜덤으로 생성할게요.'}
                            isChecked={isRandomPath}
                            onChange={() => {
                                const newIsRandomPath = !isRandomPath;
                                setIsRandomPath(newIsRandomPath);
                                if ( newIsRandomPath ) {
                                    setPath(randomString(16));
                                } else {
                                    setPath(undefined);                                        
                                }
                            }}
                            className={'RandomCustomUrlCheck'}
                        />
                    </div>
                </div>
            </div>          
        </div>
        <div className={'LinkCreationFooter'} >
            <div className={'ButtonBox'}>
                <Button className={'CancelButton'}
                    onClick={handleClose}
                >{'취소'}</Button>
                <Button className={`CreateUrlButton${!isAllFormed?" Disabled":""}`}
                    disabled={!isAllFormed}
                    onClick={ async () => {
                            
                        setIsLoading(true);

                        let res;
                        let shortLinkObj;
                        let linkPath = `/${path}`;
                        const title = titleObject[`${serviceId}:${titleId}`]
                        
                        if ( serviceId && titleId ) {
                            shortLinkObj = { serviceId, titleId, name: name || title.title, episodeId, path: linkPath};
                        } else if ( destination ) {
                            shortLinkObj = {name: name || "기타", destination: destination, path: linkPath};
                        } else {
                            if (serviceId === "other") {
                                shortLinkObj = {name: name || "기타", destination: destination, path: linkPath};
                            } else if (serviceId === 'naver' || serviceId === 'kakao') {
                                shortLinkObj = { serviceId, titleId, name: name || title.title, episodeId, path: linkPath};
                            } else {
                                toastAlert('알려지지 않은 문제가 발생했어요.', 3000, "error");
                                ChannelService.ChannelIO()('openChat');
                                setIsLoading(false);
                                return;
                            }
                        }

                        res = await createShortnerLink(shortLinkObj);
                        if ( res && res.url ) {
                            const status: ShortLinkType['status'] = "ACTIVE" 
                            const newLink = {...shortLinkObj, path: linkPath, status, createdAt: Date.now()}
                            onComplete(newLink);
                            onClose();
                            toastAlert('단축 링크를 생성했어요.', 3000, "success")
                        } else {
                            toastAlert('단축 링크 생성을 실패했어요.', 3000, "error")
                        }

                        if ( res && res.otp ) {
                            const otpInStorage = localStorage.getItem("shortLink-otp");
                            const otpArray = otpInStorage ? JSON.parse(otpInStorage) : [];
                            localStorage.setItem( "shortLink-otp", JSON.stringify([ ...otpArray, res.otp ]) );
                        }


                        setIsLoading(false);
                    }}
                >{'저장'}</Button>
            </div>
        </div>
        
        <BasicDialog
            open={isExitDailogOpen}
            onClose={() => setIsExitDialogOpen(false)}
            title={'새 단축 링크 추가를 그만하시겠어요?'}
            content={'지금 그만하시면, 작성 중인 내용은\n저장되지 않고 삭제돼요.'}
            buttons={[
                {
                    className: 'ExitButton',
                    text: "나가기",
                    onClick: () => { setIsExitDialogOpen(false); onClose(); },
                },{
                    className: 'CancelButton',
                    text: "취소",
                    onClick: () => setIsExitDialogOpen(false)
                }
            ]}
        />
        <LoadingCircle show={isLoading} />
    </NestedCascadeDrawer>)
}

export default CreateLink;