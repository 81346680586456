import React from 'react';

const NaverCallback = () => {
    React.useEffect(()=>{
        window.opener.updateNaverSession({message: "!", url: window.location})
        
        window.close();
    },[])

    return(<>
    </>);
};

export default NaverCallback;