import React from 'react';

import WithLabel from "../../../Component/WithLabel";

import "./ShortLinkGenerator.scss";
import { Button, IconButton, TextField } from '@material-ui/core';
import TextCheckBox from '../../../Component/TextCheckBox';
import { createShortnerLink } from '../../../Data/Shortner';
import LandingAutocomplete from './LandingAutocomplete';
import LoadingCircle from '../../../Component/LoadingCircleAnimated';
import { randomString } from '../../../Functions';
import { useToastAlert } from '@webtoontoday/toast';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import ChannelService from '../../../Component/ChannelService';
import { useTitles } from '../../../Recoil/Title/Titles';
import SearchTitleForPlatform from './SearchTitleForPlatform';

const platformList = [
    "네이버",
    "카카오",
    "기타"
];

// 이 페이지 input 요소의 높이
const InputHeight = 50;

const ShortLinkGenerator = ({
    onComplete
}:{
    onComplete: (generatedUrl: string) => void
}) => {
    const { toastAlert } = useToastAlert();
    const { titleObject } = useTitles();

    // naver, kakao
    const [ serviceId, setServiceId ] = React.useState<"kakao" | "naver" | "other" | undefined>(undefined);
    const [ titleId, setTitleId ] = React.useState<string |undefined>(undefined);
    const [ episodeId, setEpisodeId] = React.useState<string | undefined>(undefined);

    // 기타
    const [ destination, setDestination ] = React.useState<string |undefined>(undefined);
    const [ path, setPath ] = React.useState<string |undefined>(undefined);
    const [ isRandomPath, setIsRandomPath ] = React.useState<boolean>(false);

    // 로딩
    const [ isLoading, setIsLoading ] = React.useState<boolean>(false);

    const [ isCompleted, setIsCompleted ] = React.useState<boolean>(false);
    const [ isCollapsed, setIsCollapsed ] = React.useState<boolean>(false);

    let isAllFormed:boolean = false;
    if ( serviceId === 'other' ) {
        isAllFormed =   Boolean(serviceId) 
                    && (Boolean(path) || isRandomPath)
                    &&  Boolean(destination)
    } else {
        isAllFormed =   Boolean(serviceId) 
                    && (Boolean(path) || isRandomPath)
                    &&  Boolean(serviceId && titleId)
    }

    return(<div className={'ShortLinkGeneratorContainer'}>
        <div className={'GeneratorTitle'}>
            {'단축링크 생성'}
            {isCompleted && <IconButton size={"small"} onClick={()=>setIsCollapsed(isCollapsed => !isCollapsed)}>
                <ArrowBackIosOutlined style={{transform: isCollapsed?'rotate(90deg)':'rotate(-90deg)'}}/>
            </IconButton>}
        </div>
        <div className={`GeneratorDetail ${isCollapsed?'Hide':''}`}>
        <div className={'GeneratorSelectBox'} >
            <WithLabel label={'플랫폼'} >
                <LandingAutocomplete 
                    options={platformList}
                    onChange={()=> setTitleId(undefined)}
                    onInputChange={serviceLabel => {
                        const labelToKey: {[key: string]: "naver" | "kakao" | "other"} = {
                            "네이버": "naver",
                            "카카오": "kakao",
                            "기타"  : "other",
                        }
                        setServiceId(serviceLabel?labelToKey[serviceLabel]:undefined);
                    }}
                    disabled={isCompleted}
                    placeholder={"선택해주세요."}
                    InputProps={{
                        style: {
                            height: InputHeight
                        }
                    }}
                />
            </WithLabel>
            {/* 플랫폼이 기타일 때, */}
            {serviceId === "other"
            ?<WithLabel label={'단축할 링크'} >
                <TextField
                    type={'text'} variant={'outlined'}
                    onChange={(e) => {
                        setDestination(e.target.value);
                    }}
                    disabled={isCompleted}
                    placeholder={'단축할 링크를 입력해주세요.'}
                    InputProps={{
                        style: {
                            height: InputHeight
                        }
                    }}
                />
            </WithLabel>
            :<SearchTitleForPlatform
                key={serviceId}
                serviceId={serviceId}
                titleId={titleId}
                episodeId={episodeId}
                onSelectTitleId={(newTitleId: string |undefined) => setTitleId(newTitleId) }
                onSelectEpisodeId={(newEpisodeId: string |undefined) => setEpisodeId(newEpisodeId) }
                InputHeight={InputHeight}
                contentStyles={{backgroundColor: 'rgb(255,255,255)'}}
            />}
        </div>
        <div className={'ArrowDown'} >
            <img src={`https://static.webtoon.today/ddah/icon/arrow_down_contained.svg`} alt={'arrowDown'} />
        </div>
        <div className={'AddressGenerator'} >
            <div className={'GeneratorInputBox'} >
                <div className={'FixedAddress'}>
                    <div className={"Address"}>{"webt.ly"}</div>
                    <div className={"Slash"}>{"/"}</div>
                    <div className={"Address"}>{"***"}</div>
                    <div className={"Slash"}>{"/"}</div>
                </div >
                <WithLabel label={''} style={{width: '100%'}} >
                    <TextField
                        type={'text'} variant={'outlined'}
                        value={path ||""}
                        disabled={isRandomPath || isCompleted}
                        onChange={(e) => {
                            if (/[^ㄱ-ㅎ가-힇a-zA-Z0-9-]/.test(e.target.value)) {
                                setPath(e.target.value.replace(/[^ㄱ-ㅎ가-힇a-zA-Z0-9-]/g,'-'));

                                return;
                            }

                            setPath(e.target.value);
                        }}
                        placeholder={'단축 링크를 완성해보세요'}
                        InputProps={{
                            style: {
                                height: InputHeight
                            }
                        }}
                        style={{
                            background: `${(isRandomPath || isCompleted)?"linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #FFF":"#FFF"}`,
                        }}
                    />
                </WithLabel>
            </div>
            <div className={'RandomUrlCheckBox'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <TextCheckBox
                    text={'자동으로 생성할게요.'}
                    isChecked={isRandomPath}
                    disabled={isCompleted}
                    onChange={() => {
                        const newIsRandomPath = !isRandomPath;
                        setIsRandomPath(newIsRandomPath);
                        if ( newIsRandomPath ) {
                            setPath(randomString(16));
                        } else {
                            setPath(undefined);
                        }
                    }}
                    className={'RandomCustomUrlCheck'}
                />
            </div>
        </div>
        {!isCompleted &&
        <Button
            className={'CreateShortUrlButton'}
            disabled={(!isAllFormed || isLoading)}
            fullWidth
            style={{
                color: 'rgba(255, 255, 255, 1)',
                background: `${(!isAllFormed || isLoading) ? "linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #FFF" : 'rgba(61, 106, 255, 1)'}`,
            }}
            onClick={ async () => {
                setIsLoading(true);
                const title = titleObject[`${serviceId}:${titleId}`]
                let res;

                if ( serviceId && titleId ) {
                    res = await createShortnerLink({serviceId, titleId, name: title?.title, episodeId, path: `/${path}` });
                } else if (destination) {

                    res = await createShortnerLink({name: "기타", destination, path: `/${path}` });
                } else {
                    toastAlert('알려지지 않은 문제가 발생했어요.', 3000, "error");
                    ChannelService.ChannelIO()('openChat');
                    setIsLoading(false);
                    return;
                }

                if ( res && res.otp ) {
                    const otpInStorage = localStorage.getItem("shortLink-otp");
                    const otpArray = otpInStorage ? JSON.parse(otpInStorage) : [];
                    localStorage.setItem( "shortLink-otp", JSON.stringify([ ...otpArray, res.otp ]) );
                }

                if ( res && res.url ) {
                    setIsCompleted(true);
                    setIsCollapsed(true);
                    onComplete(res.url);
                    toastAlert('단축 링크를 생성했어요.', 3000, "success")
                } else {
                    toastAlert('단축 링크 생성을 실패했어요.', 3000, "error")
                }

                setIsLoading(false);
            }}
        >
            {"단축 링크 생성하기"}
        </Button>}
        </div>
        <LoadingCircle show={isLoading} />
    </div>);
}

export default ShortLinkGenerator;  