
import { Button } from '@material-ui/core';
import ShortLinkGenerator from './Components/ShortLinkGenerator';

import './Landing.scss';
import { useSession } from '../../Recoil/Session/Session';
import { fn, randomString } from '../../Functions';
import { useState } from 'react';
import ShortLinkResult from './Components/ShortLinkResult';

const pcTexts = [
    "시청자의 디바이스에 따라 앱 또는 웹으로 자동 연결되는 링크를 생성하고",
    "내 컨텐츠의 시청자가 실제로 웹툰을 보는 전환율을 확인할 수 있어요."
] 
const mobileTexts = [
    "시청자의 디바이스에 따라 앱 또는 웹으로 자동 연결되는 ",
    "링크를 생성하고 내 컨텐츠의 시청자가 실제로 웹툰을 ",
    "보는 전환율을 확인할 수 있어요."
]

const Landing = () => {

    const [key, setKey] = useState(randomString(12));
    const [ generatedShortUrl, setGeneratedShortUrl ] = useState<string |undefined>(undefined);
    
    return(<div className={'WebtlyLandingArea'}>
        <WebtlyLandingHeader />
        <div className={'WebtlyLandingInner'} >
            <WebtlyDescription />
            <ShortLinkGenerator
                key={key}
                onComplete={(generatedUrl: string) => {
                    setGeneratedShortUrl(generatedUrl);
                }}     
            />

            {generatedShortUrl &&
                <ShortLinkResult 
                    onReset={ ()=> {
                        setKey(randomString(12));
                        setGeneratedShortUrl(undefined);
                    } } 
                    generatedShortUrl={generatedShortUrl} 
                />}
        </div>
    </div>);
}

const WebtlyLandingHeader = () => {
    const { session } = useSession();

    return(<div className={'WebtlyLandingHeader'}>
        <div className={'WebtlyLogoBox'} >
            <img 
                src={"https://static.webtoon.today/ddah/icon/logo_webtly.svg"} 
                alt={'WebtlyLandingImage'}
                onClick={() => fn.goto("/home")}
                style={{cursor: 'pointer'}} 
            />
        </div>
        <div className={"WebtlyLoginBox"} >
            {session.memberState === "regular"
                ?<div 
                    className={'LogoutMenu'} 
                    onClick={ () => {
                        fn.goto('/home');
                    }} 
                >
                    {'관리화면'}
                </div>
                :<div className={'UnregisterInfo'} >
                    <Button
                        onClick={() => fn.goto("/home/signin")} 
                    >
                        {'로그인'}
                    </Button> 
                </div>}
        </div>
    </div>)
}

const WebtlyDescription = () => {
    return(<div className={'WebtlyLandingDescription'}>
        <div className={'Title'} >
            <div className={'SeperatedTexts'} >
                <span style={{color:'rgba(14, 169, 246, 1)'}} >{'웹툰 리뷰어'}</span>
                {'를 위한 링크 생성 도구'}
            </div>
        </div>
        <div className={'SubTexts'} >
            <div className={'SubTitle'}>
                {'생성부터 트래킹까지의 모든 과정 지원'}
            </div>
            <div className={'PC Texts'} >
                {pcTexts.map( text => <span key={text}>{text}</span>)}
            </div>
            <div className={'Mobile Texts'} >
                {mobileTexts.map( text => <span key={text}>{text}</span>)}
            </div>
        </div>
    </div>)
}

export default Landing;