
import { Button } from "@material-ui/core";
import "./ShortLinkResult.scss"
import WithLabel from "../../../Component/WithLabel";
import { useToastAlert } from '@webtoontoday/toast';
import { copyClipboard, fn, sharingPage } from "../../../Functions";


const ShortLinkResult = ({
    generatedShortUrl,
    onReset
}:{
    generatedShortUrl: string,
    onReset: () => void,
}) => {
    const {toastAlert} = useToastAlert();

    return(<div className={'ShortLinkResultArea'}>
        <div className={'ResultTitle'}>
            {'단축링크가 생성됐어요!'}
        </div>
        <div className={'ShortLinkResultContainer'} >
            <WithLabel label={'생성된 단축링크'} style={{width: '100%'}}>
                <div className={'ShowGeneratedLink'}>
                    <div className={'LinkAddress'}>{generatedShortUrl}</div>
                    <div className={'CopyButton'} 
                        onClick={async()=>{
                            navigator['share']
                                ?sharingPage({
                                    title: '', text: ``,
                                    url: generatedShortUrl,
                                    log: {what: 'copy-generatedShortUrl', detail: {codeNumber: 'n/a'}}
                                })
                                :copyClipboard(generatedShortUrl, 
                                    () => toastAlert('링크를 복사했어요.', 3000, "success"), 
                                    () => toastAlert('링크를 복사하지 못했어요.', 3000, "error")
                                )
                        }}
                    >
                        <img src={'https://static.webtoon.today/ddah/icon/copy_right.svg'} alt={"copy_right"} />
                        <span>{"복사"}</span>
                    </div>
                </div>
            </WithLabel>
        </div>
        <div className={'WebtlyResultButtonBox'} >
            <Button
                onClick={onReset}
                style={{
                    border: '1px solid rgba(137, 85, 246, 1)',
                    color: 'rgba(137, 85, 246, 1)'
                }}
            >
                {"다른 링크 생성하기"}
            </Button>
            <Button
                onClick={() => fn.goto("/home/signin")}
                style={{
                    color: 'rgba(255, 255, 255, 1)',
                    backgroundColor: 'rgba(137, 85, 246, 1)'
                }}
            >
                {"링크 트래킹하기"}
            </Button>
        </div>
    </div>)
}

export default ShortLinkResult;