import { atom, selector } from "recoil";

const pageHeaderDefault = {
    titleText: '오늘의웹툰',
    isBackable : false, isHiddenForMobile : true, isHiddenForPC : true, isStickyTop : false,
    isTitleHidden : false,
    headerStyle : {}, mobileShortcuts : null,
    isAppbarHidden : true, hasAddButton : false,
};

export const pageHeaderOptions = selector({
    key: 'pageHeaderOptions',
    get: ({ get }) => get(pageHeader),
    set: ({ set }, newValue) => set( pageHeader, {...pageHeaderDefault, ...newValue} ),
});

const pageHeader = atom<PageHeaderType>({
    key: 'pageHeader',
    default: pageHeaderDefault,
    effects: [
        ({onSet}:{onSet: Function}) => {
            onSet((newValue: PageHeaderType) => {
                window.document.title = newValue.titleText || '오늘의웹툰'; 
            });
        },
    ],
});
