import React, { CSSProperties, ReactNode } from "react";
import './OneColumnFullpageDialog.scss';

const OneColumnFullpageDialog = ({
    className = "", style = {}, title, footer, children
}: {
    className?: string, style?: CSSProperties, title?: ReactNode, footer?: ReactNode, children?: ReactNode
}) => {

    return (<div className={`OneColumnFullpageDialog ${className}`} style={{...style}}>
        <div className={"CenterDialog"}>
            <div className={"OCFDTitle"}>
                {title}
            </div>
            <div className={"OCFDContents"}>
                {children}
            </div>
            <div className={"OCFDFooter"}>
                {footer}
            </div>
        </div>
    </div>);
}

export default OneColumnFullpageDialog;