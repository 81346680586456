import React from 'react';
import './WithLabel.scss';
import InfoIconContainer from './InfoIconContainer';

const WithLabel = ({
    label,
    children,
    descriptionSrc = '',
    descriptionStyle = {},
    className = '',
    style = {},
    contentStyles = {},
}: {
    label: string;
    children: React.ReactNode;
    descriptionSrc?: string;
    descriptionStyle?: React.CSSProperties;
    className?: string;
    style?: React.CSSProperties;
    contentStyles?: React.CSSProperties;
}) => {
    return (
        <div className={`WithLabelContainer ${className}`} style={{ ...style }}>
            <div className={'Label'}>
                {label}
                {descriptionSrc && <InfoIconContainer descriptionSrc={descriptionSrc} descriptionStyle={{ ...descriptionStyle }} />}
            </div>
            <div className={'Content'} style={{ ...contentStyles }} >{children}</div>
        </div>
    );
};

export default WithLabel;
