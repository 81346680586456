import { Button, Dialog, DialogActions, DialogTitle, IconButton, Popover } from "@material-ui/core";
import "./Link.scss"
import "../../../Component/WTConfirmationStyledDialog.scss";
import { useEffect, useState } from "react";
import { ShortLinkType, deleteShortLink } from "../../../Data/Shortner";
import { useTitles } from "../../../Recoil/Title/Titles";
import { fn, thumbnailize } from "../../../Functions";
import { useToastAlert } from "@webtoontoday/toast";
import { useShortners } from "../../../Recoil/Shortner/Shortner";
import { useEpisodes } from "../../../Recoil/Title/Episodes";
import LoadingCircle from "../../../Component/LoadingCircle";
import LinkCreationDrawer from "./Components/LinkCreationDrawer";

const Link = () => {
    const {fetch: fetchTitles} = useTitles();
    const {fetch: fetchEpisodes} = useEpisodes();
    const {fetch: fetchShortner, shortnerList, refresh: refreshShortner} = useShortners();
    const [isLinkCreationDrawerOpen, setIsLinkCreationDrawerOpen] = useState<boolean>(false);

    useEffect(()=>{
        (async ()=>{
            fetchShortner();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(()=>{

        fetchTitles(
            shortnerList
                .filter(({serviceId, titleId}) => Boolean(serviceId) && Boolean(titleId))
                .map(({serviceId, titleId}) => ({serviceId, titleId} as {serviceId: string, titleId: string}))
            );
        
        shortnerList
            .filter(({serviceId, titleId}) => Boolean(serviceId) && Boolean(titleId))
            .forEach(({serviceId, titleId}) => {
                fetchEpisodes({serviceId, titleId} as {serviceId: string, titleId: string})
            })
            
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shortnerList])

    let linkContent;
    if(!shortnerList || shortnerList.length === 0){
        linkContent = (
            <div className="LinkContentEmpty">
                <div>
                    <div>
                        <h1>{'웹틀리에서 단축 링크를 생성해보세요'}</h1>
                        <h4>{'긴 링크를 줄이고 링크의 내용을 커스텀하여\n더 많은 관심을 유도해보세요'}</h4>
                    </div>
                    <div>
                        <Button
                            style={{
                                borderRadius: "60px",
                                padding: "12px 32px",
                                background: "var(--gradient-primary, linear-gradient(135deg, #9AABE2 0%, #3D6AFF 100%))",
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "1.25rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "32px",
                                letterSpacing: "0.2px",
                            }}
                            onClick={() => setIsLinkCreationDrawerOpen(true)}
                        >
                            {'링크 만들기'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    } else{
        linkContent = (
            <div className="LinkContent">
                {shortnerList.filter(link => link.status === 'ACTIVE').map(link =>
                <LinkCard key={link.path} link={link}/>
                )}
            </div>
        )
    }


    return (
        <div className="Link">
            <div className="LinkHeader">
                <span>
                    {'링크'}
                </span>
                <Button
                    style={{
                        borderRadius: '8px',
                        padding: '6px 12px',
                        backgroundColor: 'rgba(61, 106, 255, 1)',
                        color: 'rgba(255, 255, 255, 1)',
                        fontSize: '1rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0.16px',
                    }}
                    onClick={() => setIsLinkCreationDrawerOpen(true)}
                >
                    {'링크 만들기'}
                </Button>
            </div>
            {linkContent}
            <LinkCreationDrawer
                open={isLinkCreationDrawerOpen}
                onClose={() => setIsLinkCreationDrawerOpen(false)}
                onComplete={(link: ShortLinkType) => {
                    const {serviceId, titleId} = link;
                    if(serviceId && titleId){
                        fetchTitles([{serviceId, titleId}])
                        fetchEpisodes({serviceId, titleId})
                    }
                    refreshShortner();
                }}             
            />
        </div>
    )
}

export const LinkCard = ({link}: {link: ShortLinkType}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLinkCreationDrawerOpen, setIsLinkCreationDrawerOpen] = useState<boolean>(false);
    const [isLinkEdit, setIsLinkEdit] = useState<boolean>(false);

    const {titleObject, fetch: fetchTitles} = useTitles();
    const {episodeObject, fetch: fetchEpisodes} = useEpisodes();
    const {toastAlert} = useToastAlert();

    const {refresh} = useShortners();

    const title = titleObject[`${link.serviceId}:${link.titleId}`];
    const episodeList = episodeObject[`${link.serviceId}:${link.titleId}`]
    const menuItemContent: {icon: string, label: string, onClick: Function}[] = [
        {
            icon: "https://static.webtoon.today/ddah/icon/edit_fan_fill.svg",
            label: "편집",
            onClick: () => {
                setIsLinkCreationDrawerOpen(true);
                setIsLinkEdit(true);
            }
        },
        {
            icon: "https://static.webtoon.today/ddah/icon/link_copy.svg",
            label: "다시 생성",
            onClick: () => {
                setIsLinkCreationDrawerOpen(true);
                setIsLinkEdit(false);
            }
        },
        {
            icon: "https://static.webtoon.today/ddah/icon/trash.svg",
            label: "삭제",
            onClick: () => {
                setIsDeleteDialogOpen(true);
            }
        },
    ]

    return (
        <div className="LinkCard">
            <div className="LinkCardInfo">
                <div className="LinkCardInfoThumbnail">
                    <img src={title?thumbnailize(title.thumbnail):`https://static.webtoon.today/noimage.jpg`} alt="썸네일" width={'102px'} height={'132px'} style={{borderRadius: '8px'}} />
                </div>
                <div className="LinkCardInfoDetail">
                    <div className="LinkCardInfoDetailTitle">
                        <div>{title?`${title.title} - ${(link.episodeId && episodeList) ? episodeList.find(({episodeId}) => episodeId === link.episodeId)?.episodeName : '작품 홈'}`:`기타`}</div>
                        <Button disableElevation href={`/home/dashboard#${link.path}`} onClick={fn.gotoByAnchor}>{link.name}</Button>
                    </div>
                    <div className="LinkCardInfoDetailLink">
                        <Button disableElevation href={`https://webt.ly${link.path}`} onClick={fn.gotoByAnchor}><span>{link.path}</span></Button>
                        <IconButton size={"small"} onClick={()=>{
                            const url = `https://webt.ly${link.path}`;

                            const $textarea = document.createElement("textarea");
                    
                            document.body.appendChild($textarea);
                            
                            $textarea.value = url;
                            $textarea.select();
                            
                            document.execCommand('copy');
                            document.body.removeChild($textarea);

                            toastAlert("링크가 복사되었어요.", 3000, "info");
                        }}>
                            <img src="https://static.webtoon.today/ddah/icon/copy_right.svg" alt="복사" width={'20px'} height={'20px'}/>
                        </IconButton>
                    </div>
                    <div className="LinkCardInfoDetailDate">
                        <img src="https://static.webtoon.today/ddah/icon/calendar_s.svg" alt="날짜" width={'14px'} height={'14px'} />
                        <span>{new Date(link.createdAt * 1000).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>
            <div className="MoreButton">
                <img
                    src="https://static.webtoon.today/ddah/icon/more_horiz.svg"
                    alt="더보기"
                    width={'20px'}
                    height={'20px'}
                    style={{
                        borderRadius: '4px',
                        border: '1px solid rgba(236, 237, 243, 1)',
                        padding: '6px',
                        cursor: 'pointer'
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                />
            </div>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                style={{
                    top: '8px'
                }}
                className="LinkCardMenu"
            >
                <div className="LinkCardMenuList">
                    {menuItemContent.map(({icon, label, onClick}, index) => (
                    <div
                        key={label}
                        style={{
                            color: `${index === 2 ? "rgba(235, 81, 71, 1)" : "rgba(60, 70, 81, 1)"}`
                        }}
                        onClick={() => {
                            setAnchorEl(null);
                            onClick();
                        }}
                        className="LinkCardMenuItem"
                    >
                        <img src={icon} alt={label} width={'20px'} height={'20px'} />
                        <span>{label}</span>
                    </div>
                    ))}
                </div>
            </Popover>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                className="WTConfirmationStyledDialog"
            >
                <DialogTitle>
                    <div className="Title">
                        {'링크를 삭제하시겠어요?'}
                    </div>
                    <div className="SubTitle">
                        {'해당 링크로 접속 시\n'}
                        <span style={{color: 'rgba(235, 81, 71, 1)', fontWeight: 600}}>{'webtly의 에러페이지'}</span>
                        {'로 이동해요.'}
                    </div>
                </DialogTitle>
                <DialogActions>
                    <Button
                        style={{
                            borderRadius: '8px',
                            padding: '12px',
                            backgroundColor: 'rgba(235, 81, 71, 1)',
                            color: 'rgba(255, 255, 255, 1)',
                            fontFamily: 'inherit'
                        }}
                        onClick={async () => {
                            setIsLoading(true);
                            const res = await deleteShortLink(link.path);
                            setIsLoading(false);
                            setIsDeleteDialogOpen(false);
                            if(res){
                                refresh();
                            } else{
                                toastAlert("다시 시도해 주세요", 3000, "error");
                            }
                        }}
                        disabled={isLoading}
                    >
                        {'링크 삭제하기'}
                    </Button>
                    <Button
                        style={{
                            borderRadius: '8px',
                            color: 'rgba(60, 70, 81, 1)',
                            fontFamily: 'inherit'
                        }}
                        onClick={() => setIsDeleteDialogOpen(false)}
                    >
                        {'취소'}
                    </Button>
                </DialogActions>
            </Dialog>
            <LinkCreationDrawer
                open={isLinkCreationDrawerOpen}
                onClose={() => setIsLinkCreationDrawerOpen(false)}
                onComplete={(link: ShortLinkType) => {
                    const {serviceId, titleId} = link;
                    if(serviceId && titleId){
                        fetchTitles([{serviceId, titleId}])
                        fetchEpisodes({serviceId, titleId})
                    }
                    refresh();
                }}
                name={link.name}
                serviceId={link.serviceId as "naver" | "kakao" | undefined}
                titleId={link.titleId}
                episodeId={link.episodeId}
                destination={link.destination}
                path={isLinkEdit ? link.path : undefined}
            />
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default Link;