import { Popper, PopperProps } from "@material-ui/core";

import './WebtoonTodayNormalPopper.scss';

const WebtoonTodayNormalPopper = (props: React.PropsWithChildren<PopperProps>) => {

    return(<Popper {...props} className={'WebtoonTodayNormalPopper'} >
        {props.children}
    </Popper>)
}

export default WebtoonTodayNormalPopper;