import React from 'react';
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@material-ui/core";
import { logBehavior } from '../../../../../Data/Behavior';

const GoogleIcon = 'https://static.webtoon.today/challenge/GoogleIcon.png';

const GoogleLogin = ({ onSuccess }:{onSuccess: (...e: any[]) => void}) => {

    const googleSocialLogin = useGoogleLogin({
        flow: 'auth-code',
        scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
        onSuccess: async (codeResponse) => {
            logBehavior('login-complete-snsLogin(success)', {});
            onSuccess(codeResponse)
        },
        onError: () => {
            logBehavior('login-complete-snsLogin(fail)', {});
        }
    });
    
    return (
        <Button
            onClick={(e)=>{
                logBehavior('login-start-snsLogin', {});
                googleSocialLogin();
            }} 
            className={'Button GoogleLoginButton'}
            style={{
                backgroundColor: 'rgb(255,255,255)',
                color:'black',
                height: '45px',
                border: '1px solid rgb(51,51,51)',
                fontFamily: 'inherit',
                textTransform: 'none'
            }}
            >
            <img src={GoogleIcon} alt={'GoogleLogo'} style={{maxHeight: '20px', position: 'absolute', left: '15px'}} />
            {'Google 로그인'}
        </Button>
    )
}

export default GoogleLogin;
