import axios from "axios";
import ss from './Session';

export type episodeType = {
  "episodeId": string,
  "episodeName": string,
  "createdAt": number
}

export const readEpisode = async (serviceId: string, titleId: string): Promise<EpisodeType[]> => {

    try{
        let res = await axios.get(`https://challenge-api.webtoon.today/market/episode?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}