import { Settings } from '@material-ui/icons';
import {Button} from '@material-ui/core';
import React, { useState } from 'react';
import './AppBar.scss';

import { fn } from '../Functions';

import AnchorButton from './AnchorButton';
import ChannelService from './ChannelService';
import { useRecoilValue } from 'recoil';
import { useSession } from '../Recoil/Session/Session';
import { pageHeaderOptions } from '../Recoil/PageHeader/PageHeader';

const AppBar = () => {

    const pageHeader = useRecoilValue(pageHeaderOptions)
    
    const [isViewMore, setIsViewMore] = useState<boolean>(false);
    
    const { isAppbarHidden: isHidden } = pageHeader;

    return (
    <div className={`AppBar`} style={{...(isHidden?{display: 'none'}:{})}}>
        <div className={'AppBarInner'} >
            {[
                {pathname: '/', icon: 'https://static.webtoon.today/ddah/icon/icon_home.svg', icon_unActivated: 'https://static.webtoon.today/ddah/icon/icon_home_unActivated.svg', alt: 'icon_home', name: '홈'},
                {pathname: '/search', icon: 'https://static.webtoon.today/ddah/icon/icon_search.svg', icon_unActivated: 'https://static.webtoon.today/ddah/icon/icon_search_unActivated.svg', alt: 'icon_search', name: '검색'},
                {pathname: '/home/trend', icon: 'https://static.webtoon.today/ddah/icon/icon_trendSearch.svg', icon_unActivated: 'https://static.webtoon.today/ddah/icon/icon_trendSearch_unActivated.svg', alt: 'icon_trendSearch', name: '트렌드'},
            ].map(({pathname, icon, icon_unActivated, alt, name}) => {
                let isActivated = (window.location.pathname === pathname && !isViewMore);
                return <Button key={pathname} className={`AppButtonBox ${isActivated?'Activated':''}`} fullWidth href={pathname} onClick={(event) => {fn.gotoByAnchor(event); setIsViewMore(false);}} >
                    <div className={'AppButton Home'} >
                        <img src={`${isActivated?icon:icon_unActivated}`} alt={alt} className={'Icons'} />
                        {name}
                    </div>
                </Button>
            })}
            <Button className={`AppButtonBox ${isViewMore?'Activated':''}`} fullWidth onClick={() => setIsViewMore(!isViewMore)}>
                <div className={'AppButton History'} >
                    <img src={`${isViewMore?'https://static.webtoon.today/ddah/icon/icon_showMoreMenu.svg':'https://static.webtoon.today/ddah/icon/icon_showMoreMenu_unActivated.svg'}`} alt={'icon_showMoreMenu'} className={'Icons'} /> 
                    {"더보기"}
                </div>
            </Button>
            {isViewMore && <ViewMore setIsViewMore={setIsViewMore} />}
        </div>
    </div>
    );
}

const ViewMore = ({setIsViewMore}: {setIsViewMore: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const { session } = useSession();

    let authorInfoDiv;
    switch(session.memberState){
        case 'regular':
            authorInfoDiv = (
                <div className={'AuthorInfo SignedIn'}>
                    <div className={'SettingIcon'}>
                        <AnchorButton href={`/account`} onClick={() => setIsViewMore(false)}>
                            <Settings />
                        </AnchorButton>
                    </div>
                    <div className={'AuthorImage'} onClick={()=>{
                        fn.goto('/account');
                        setIsViewMore(false);
                    }} >
                        <img style={{width:42, height:42, borderRadius: '50%'}} src={session.image && session.image !== 'https://static.webtoon.today/noimage.jpg'?session.image:'https://static.webtoon.today/noprofile.jpg'} alt={session.name || undefined}/>
                    </div>
                    <div className={'AuthorName'}>
                        {session.name}
                    </div>
                    <div className={'AddWebtoonBox'}>
                        <AnchorButton
                            href={'/enroll'}
                            onClick={() => setIsViewMore(false)}
                            className={'AddWebtoonButton'}
                            style={{width: 175, height: 46, backgroundColor: 'rgba(30, 158, 255, 1)', borderRadius: 55, justifyContent: 'center', alignItems: 'center'}}
                        >
                            {'작품 등록'}
                        </AnchorButton>
                    </div>
                </div>
            )
            break;
        default:
            authorInfoDiv = (
                <div className={'AuthorInfo'}>
                    <div className={'SubLabel'}>
                        {'오늘의웹툰 회원이 아니신가요?'}
                    </div>
                    <div className={'Label'}>
                        {'작품을 감상하고 자유롭게 연재해보세요!'}
                    </div>
                    <div className={'LoginButton'}>
                        <AnchorButton
                            href={'/login'}
                            onClick={() => setIsViewMore(false)}
                            className={'Login'}
                            style={{width: '100%', height: 46, backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: 5, justifyContent: 'center', alignItems: 'center'}}
                        >
                            {'로그인'}
                        </AnchorButton>
                    </div>
                </div>
            )
            break;
    }

    return (
        <div className={'ViewMore'}>
            {authorInfoDiv}
            <div>
                <div onClick={() => {
                    setIsViewMore(false);
                    fn.goto("/portfolio");
                }} >
                    {'포트폴리오'}
                </div>
                <div onClick={() => {
                    setIsViewMore(false);
                    fn.goto("/mypage");
                }} >
                    {'MY'}
                </div>
            </div>
            <div>
                <div onClick={() => {
                    setIsViewMore(false);
                    fn.goto("https://webtoon.today");
                }} >
                    {'서비스 소개'}
                </div>
                <div className='Analysis' onClick={() => {
                    setIsViewMore(false);
                    fn.goto("/application/analysis");
                }} >
                    {'작품 분석 신청'}
                </div>
                <div onClick={() => {
                    setIsViewMore(false);
                    ChannelService.ChannelIO()('openChat')
                }} >
                    {'문의하기'}
                </div>
            </div>
            <div className={'CorpInfo'}>
                <div className={'CorpInfomations'}>
                    {corpInfo.map((content, index) =>
                    <span className={'Infomation'} key={`${content}:${index}`} onClick={() => {
                        if(content === '공지사항'){
                            setIsViewMore(false);
                            fn.goto('https://webtoon.today/notice');
                        } else if(content === '개인정보처리방침'){
                            setIsViewMore(false);
                            fn.goto('https://webtoon.today/privacypolicy/kr')
                        }
                    }} >
                        {content}
                    </span>)
                    }
                </div>
                <div>
                    {'ⓒ 주식회사 오늘의웹툰'}
                </div>
            </div>
        </div>
    );
}

const corpInfo = ['상호 : 주식회사 오늘의웹툰', '대표자 : 진수글', '공지사항', '개인정보처리방침', '사업자 등록 번호 : 765-81-02007', '주소 : 서울특별시 강남구 테헤란로 2길 27, 1111호 (역삼동, 패스트파이브 빌딩) ']

export default AppBar;