
import { ShortLinkType } from "../../../../Data/Shortner";

import CreateLink from "./CreationLink";
import EditLink from "./EditLink";



const LinkCreationDrawer = ({ 
    open, onClose, onComplete, 
    name, 
    serviceId: givenServiceId, titleId, episodeId,
    path, destination
}:{ 
    open: boolean, 
    onClose: () => void,
    onComplete: (link: ShortLinkType) => void,
    name?: string |undefined;
    serviceId?: "kakao" | "naver" | "other" | undefined;
    titleId?: string |undefined;
    episodeId?: string |undefined;
    path?: string |undefined;
    destination?: string |undefined;
}) => {

    let serviceId: "kakao" | "naver" | "other" | undefined = undefined;
    if ( destination                ) { serviceId = "other" };
    if ( givenServiceId === "kakao" ) { serviceId = "kakao" };
    if ( givenServiceId === "naver" ) { serviceId = "naver" };

    if ( path ) {
        return(<EditLink
            open={open} onClose={onClose} onComplete={onComplete} 
            name={name}
            serviceId={serviceId}
            titleId={titleId}
            episodeId={episodeId}
            path={path}
            destination={destination}
        />)
    } else {
        return(<CreateLink 
            open={open} onClose={onClose} onComplete={onComplete} 
            name={name}
            serviceId={serviceId}
            titleId={titleId}
            episodeId={episodeId}
            path={path}
            destination={destination}
        />)
    }
}

export default LinkCreationDrawer;