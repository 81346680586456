import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import WithLabel from '../../../../Component/WithLabel';

import './PersonalSetting.scss';
import { useSession } from '../../../../Recoil/Session/Session';
import { updateProfile } from '../../../../Data/Profile';
import { fn } from '../../../../Functions';
import { logBehavior } from '../../../../Data/Behavior';
import { transferShortenerByOTPs } from '../../../../Data/Shortner';

const termTitles = ['[필수] 서비스 제공 약관에 동의', '[필수] 개인정보 수집 및 이용 동의', '[선택] 마케팅 활용 동의'] as const;

const PersonalSetting = ({
    userInfo,
    setUserInfo
}: {
    userInfo: UserInfoType,
    setUserInfo: React.Dispatch<React.SetStateAction<UserInfoType>>
}) => {
    const { email, name, gender, birthday } = userInfo;
    if(!email){
        fn.goto('/home/signin');
    }

    const { session, sessionRefresh } = useSession();
    
    const [ pageIndex, setPageIndex ] = useState<0 | 1>(0);
    const [ isTermInfoDialogOpen, setIsTermInfoDialogOpen ] = useState<boolean>(false);
    const [ termInfoIndex, setTermInfoIndex ] = useState<number>(0);
    const [ selectedTerms, setSelectedTerms ] = useState<Set<string>>(new Set());

    if(pageIndex === 0){
        const isButtonEnabled = Boolean(name && gender && birthday);
        return (
            <div className="PersonalSetting">
                <div className="PersonalSettingContent0">
                    <div>
                        <WithLabel label='이름'>
                            <div className="Input">
                                <input
                                    placeholder='사용하실 이름을 입력해주세요.'
                                    value={name}
                                    onChange={(e) => setUserInfo(prev => ({...prev, name: e.target.value}))}
                                />
                            </div>
                        </WithLabel>
                        <WithLabel label='성별'>
                            <div>
                                {[['남자', 'M'], ['여자', 'F'], ['알리지 않음', 'N']].map(([key, value]) => (
                                <Button
                                    key={key}
                                    onClick={() => setUserInfo(prev => ({...prev, gender: value as 'M' | 'F' | 'N'}))}
                                    style={{
                                        border: `1px solid ${gender === value ? 'rgba(61, 106, 255, 1)' : 'rgba(236, 237, 243, 1)'}`,
                                        padding: '12px 10px',
                                        width: '33.33%',
                                        color: `${gender === value ? 'rgba(60, 70, 81, 1)' : 'rgba(189, 189, 194, 1)'}`,
                                        fontSize: '0.9375rem',
                                        fontFamily: 'inherit'
                                    }}
                                >
                                    {key}
                                </Button>
                                ))}
                            </div>
                        </WithLabel>
                        <WithLabel label='생년월일'>
                            <div className="Input">
                                <input
                                    type="date"
                                    value={birthday}
                                    onChange={(e) => setUserInfo(prev => ({...prev, birthday: e.target.value}))}
                                    className={`${!birthday ? 'Empty' : ''}`}
                                />
                            </div>
                        </WithLabel>
                    </div>
                    <div>
                        <Button
                            onClick={() => setPageIndex(1)}
                            style={{
                                borderRadius: '8px',
                                padding: '12px 46px',
                                background: `${isButtonEnabled ? 'rgba(51, 51, 53, 1)' : 'linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #333335'}`,
                                color: `${isButtonEnabled ? 'rgba(255, 255, 255, 1)' : 'rgba(220, 220, 220, 1)'}`,
                                fontFamily: 'inherit',
                                fontSize: '1rem',
                                fontWeight: 700
                            }}
                            disabled={!isButtonEnabled}
                        >
                            {'다음'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    } else{
        const isButtonEnabled = selectedTerms.has('[필수] 서비스 제공 약관에 동의') && selectedTerms.has('[필수] 개인정보 수집 및 이용 동의');
        return (
            <div className="PersonalSetting">
                <div className="PersonalSettingContent1">
                    <div>
                        <div className="Header">
                            {'서비스 이용약관 동의'}
                        </div>
                        <div className="Content">
                            <div className="SelectAllItems">
                                <label className={'CustomCheckBox'} >
                                    <input
                                        type='checkbox'
                                        checked={selectedTerms.size === termTitles.length}
                                        onChange={() => {
                                            if(selectedTerms.size === termTitles.length){
                                                setSelectedTerms(new Set());
                                            } else{
                                                setSelectedTerms(new Set(termTitles));
                                            }
                                        }}
                                    />
                                    <span className={'CheckMark'} />
                                </label>
                                <span>
                                    {'약관 전체 동의하기'}
                                </span>
                            </div>
                            <div>
                                {termTitles.map((content, index) => (
                                <div key={content} className="Item">
                                    <div>
                                        <img
                                            src="https://static.webtoon.today/ddah/icon/icon_checkmark.svg"
                                            alt="체크"
                                            width={'20px'}
                                            height={'20px'}
                                            className={`${selectedTerms.has(content) ? 'Selected' : ''}`}
                                            onClick={() => {
                                                let newSelectedTerms = new Set([...selectedTerms]);
                                                if(selectedTerms.has(content)){
                                                    newSelectedTerms.delete(content);
                                                } else{
                                                    newSelectedTerms.add(content);
                                                }
                                                setSelectedTerms(newSelectedTerms);
                                            }}
                                        />
                                        <span>
                                            {content}
                                        </span>
                                    </div>
                                    <span 
                                        onClick={() => {
                                            setTermInfoIndex(index);
                                            setIsTermInfoDialogOpen(true);
                                        }}
                                    >
                                        {'보기'}
                                    </span>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button
                            style={{
                                padding: '12px 46px',
                                borderRadius: '8px',
                                background: `${isButtonEnabled ? 'rgba(61, 106, 255, 1)' : 'linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), rgba(61, 106, 255, 1)'}`,
                                color: `${isButtonEnabled ? 'rgba(255, 255, 255, 1)' : 'rgba(220, 220, 220, 1)'}`,
                                fontFamily: 'inherit',
                                fontSize: '1rem',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                lineHeight: '24px',
                                letterSpacing: '0.16px'
                            }}
                            disabled={!isButtonEnabled}
                            onClick={async () => {
                                try{
                                    await updateProfile({addressid: session.userid || undefined, email, name, gender, birthday, consentList: [...selectedTerms]})
                                    await sessionRefresh();

                                    await transferShortenerByOTPs();
                                    logBehavior('signin(success)', {name, email, addressid: session.userid, gender, birthday, consentList: [...selectedTerms]})
                                    logBehavior('signup', {name, email});
                                    fn.goto('/home');
                                } catch(e){
                                    logBehavior('singin(fail)', {name, email, addressid: session.userid, gender, birthday, consentList: [...selectedTerms]} )
                                    console.error(e);
                                }
                            }}
                        >
                            {'가입하기'}
                        </Button>
                    </div>
                    <TermDialog
                        isOpen={isTermInfoDialogOpen}
                        onClose={() => setIsTermInfoDialogOpen(false)}
                        termInfoIndex={termInfoIndex}
                    />
                </div>
                
            </div>
        )
    }
};

const TermDialog = ({isOpen, onClose, termInfoIndex}: {isOpen: boolean, onClose: Function, termInfoIndex: number}) => {
    let termInfoContent;
    if(termInfoIndex === 0){
        termInfoContent = <iframe title={'termsOfUse'} src={'https://webtoon.today/termsofuse/kr'} style={{width: '100%', height: '80vh', maxHeight: '100%', border: 0}} />;
    } else if(termInfoIndex === 1){
        termInfoContent = <iframe title={'privacypolicy'} src={'https://webtoon.today/privacypolicy/kr'} style={{width: '100%', height: '80vh', maxHeight: '100%', border: 0}} />;
    } else{
        termInfoContent = (
            <div style={{padding: '30px', fontSize: '108%'}}>
                <p>{'마케팅 정보 활용 동의'}</p>
                {`마케팅 정보 활용에 동의하지 않으셔도 오늘의웹툰 서비스는 정상적으로 이용하실 수 있습니다.
                동의할 경우 각종 소식 및 이벤트 관련 안내가 이메일로 전송됩니다.`}
                <div style={{paddingLeft: 20, fontSize: '97.2%'}}>
                    <p>{'마케팅 정보 활용 목적'}</p>
                    <div style={{paddingLeft: 20, fontSize: '78.7%'}}>
                        {'-'}
                        {'이벤트 및 광고성 정보 전송'}
                    </div>
                    <div style={{paddingLeft: 20, fontSize: '78.7%'}}>
                        {'-'}
                        {'서비스 관련 정보 전송'}
                    </div>
                </div>
                <div style={{paddingLeft: 20, fontSize: '97.2%'}}>
                    <p>{'보유 및 이용기간'}</p>
                    <div style={{paddingLeft: 20, fontSize: '78.7%'}}>
                        {'-'}
                        {'이용자가 동의를 철회하거나, 탈퇴시까지 보유/이용'}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Dialog fullScreen onClose={() => onClose()} open={isOpen} style={{maxWidth: '600px', margin: '0 auto'}} >
            <DialogContent>
                <div style={{width: '100%', height: '100%'}}>
                    {termInfoContent}
                </div>
            </DialogContent>
            <DialogActions style={{ padding: '0 32px 16px 32px'}} >
                <Button autoFocus variant={'contained'} onClick={() => onClose()} style={{backgroundColor: 'rgba(61, 106, 255, 1)', color: 'rgba(255, 255, 255, 1)'}} >
                    {'확인'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PersonalSetting;